import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService, USD } from 'src/app/core/services/settings.service';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(private settingsService: SettingsService) { }

  transform(price: number): string {
    let activeUnit = this.settingsService.activeUnit;
    if (!activeUnit) {
      return '';
    }
    let unitName = activeUnit.symbol;
    if (activeUnit.name === USD) {
      return `${(price * activeUnit.rate).toFixed(2)} ${unitName}`;
    }
    let lbpPrice = Math.round(price * activeUnit.rate / 100) * 100;
    return `${lbpPrice.toFixed(0)} ${unitName}`;
  }

}
