import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductOrder } from 'src/app/core/models/product-order';
import { CartService } from 'src/app/core/services/cart.service';
import { ConfirmationService } from 'primeng/api';
import { ChangeProductModel } from '../cart-content/cart-content.component';

@Component({
  selector: 'app-cart-table',
  templateUrl: './cart-table.component.html',
  styleUrls: ['./cart-table.component.scss']
})
export class CartTableComponent implements OnInit {
  @Input('canDelete') canDelete = true;
  @Input('canChangeCount') canChangeCount = true;
  @Output('onClickProduct') onClickProduct: EventEmitter<void> = new EventEmitter<void>();
  @Output('onRemoveAll') onRemoveAll: EventEmitter<void> = new EventEmitter<void>();
  isLoading = true;
  val: number = 0;

  constructor(private cartService: CartService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.update();
  }

  async update() {
    await this.cartService.updateInBG();
    this.isLoading = false;
  }

  get cart() {
    return this.cartService.cart;
  }

  get products() {
    return this.cartService.cart?.productOrders ?? [];
  }

  get count() {
    return this.cartService.cart.count;
  }

  get price() {
    return this.cartService.cart.price;
  }

  removeProduct(uid: string) {
    this.cartService.removeFromCard(uid);
    if (this.cartService.cart.count === 0) {
      this.onRemoveAll?.emit();
    }
  }

  changeCount(changeProductModel: ChangeProductModel) {
    this.cartService.changeProductCount(
      changeProductModel.productOrder,
      changeProductModel.count
    );
  }

  clickProduct() {
    this.onClickProduct?.emit();
  }
}
