<!-- <div class="language-switch" dir={{dir}}>
  <fa-icon
    class="social-media-bar_icon"
    [icon]="faLanguage"
    size="lg"
  ></fa-icon>
  <mat-form-field>
    <mat-select [(value)]="currentLang" (selectionChange)="selectLang($event)">
      <mat-option
        *ngFor="let lang of languages"
        value={{lang.languageCode}}
      >
        {{dir === 'rtl' ? lang.flag : ''}} {{lang.name}} {{dir === 'ltr' ? lang.flag : ''}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div> -->

<div class="language-switch">
  <input type="checkbox" class="language-switch__checkbox" id="checkbox" (change)="selectLang($event)" [(ngModel)]="isChecked">
  <label for="checkbox" class="language-switch__checkbox-label">
    <i class="language-switch__us-flag"></i>
    <i class="language-switch__lb-flag"></i>
    <span class="language-switch__ball"></span>
  </label>
</div>
