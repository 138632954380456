import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import Category, { Subcategory } from 'src/app/core/models/category';
import { InQueryParams } from 'src/app/core/models/query-params';
import { BrandsService } from 'src/app/core/services/brands.service';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { capitalize } from 'src/app/core/utils/capitalize-query-params';

interface CategoryItem {
  name: string;
  display: string;
}

@Component({
  selector: 'products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss']
})
export class ProductsFilterComponent implements OnInit {
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  @Input('showHeader') showHeader = false;
  @Input('modifiers') modifiers: string = '';
  @Input('hideSearch') hideSearch: boolean = false;
  @Input('selectedCategory') selectedCategory: Category = null;
  @Input('selectedSubcategory') selectedSubcategory: Subcategory = null;
  @Output('onSearch') onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output('onClickCategory') onClickCategory: EventEmitter<Category> = new EventEmitter<Category>();
  @Output('onClickSubcategory') onClickSubcategory: EventEmitter<Subcategory> = new EventEmitter<Subcategory>();
  inQueryParams$: Observable<InQueryParams> = null;
  categories: Array<Category> = [];
  lang: string = 'en';
  subscription: Subscription;
  categoryItems: CategoryItem[] = [];
  subcategoryItems: CategoryItem[] = [];
  private isDragging = false;
  private startPosition: number;

  startDragging(event: MouseEvent): void {
    this.isDragging = true;
    this.startPosition = event.clientX;
    // Optional: Prevent text selection while dragging
    event.preventDefault();
  }

  onDragging(event: MouseEvent): void {
    if (!this.isDragging) return;

    const currentPosition = event.clientX;
    const distanceMoved = currentPosition - this.startPosition;
    this.scrollContainer.nativeElement.scrollLeft -= distanceMoved;
    this.startPosition = currentPosition; // Reset start position for next move
  }

  stopDragging(): void {
    this.isDragging = false;
  }

  scrollLeft(): void {
    // Adjust scrollAmount to your preference
    const scrollAmount = 200;
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  }

  scrollRight(): void {
    const scrollAmount = 200;
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }

  constructor(
    public i18nService: I18nService,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private brandsService: BrandsService
  ) { }

  ngOnInit(): void {
    this.categories = this.categoriesService.categories;
    this.inQueryParams$ = this.route.queryParams;
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.categoryItems = [
          {
            name: 'Cosmetics',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Games',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Others',
            display: this.i18nService.data.navbar.others
          }
        ];
        this.subcategoryItems = [
          {
            name: 'Hair Care',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Skin Care',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Lips Care',
            display: this.i18nService.data.navbar.lipsCare,
          },
          {
            name: 'Body Care',
            display: this.i18nService.data.navbar.bodyCare,
          },
          {
            name: 'Hand Care',
            display: this.i18nService.data.navbar.handCare,
          },
          {
            name: 'Foot Care',
            display: this.i18nService.data.navbar.footCare,
          },
          {
            name: 'Makeup',
            display: this.i18nService.data.navbar.makeup
          },
          {
            name: 'Perfumes',
            display: this.i18nService.data.navbar.perfumes
          },
          {
            name: 'Boy Games',
            display: this.i18nService.data.navbar.boyGames
          },
          {
            name: 'Girl Games',
            display: this.i18nService.data.navbar.girlGames
          },
          {
            name: 'Puzzles',
            display: this.i18nService.data.navbar.puzzles
          },
          {
            name: 'Educational',
            display: this.i18nService.data.navbar.educational
          },
          {
            name: 'Arts & Craft',
            display: this.i18nService.data.navbar.artsAndCrafts
          },
          {
            name: 'Outdoor Games',
            display: this.i18nService.data.navbar.outdoorGames
          },
        ];
      }
    );
  }

  search(search: string) {
    this.onSearch.emit(search);
  }

  clickCategory(category: Category) {
    this.onClickCategory.emit(category);
  }

  clickSubcategory(subcategories: Subcategory) {
    this.onClickSubcategory.emit(subcategories);
  }

  getBrand(brandUid: string) {
    return this.brandsService.brands.find(({ uid }) => uid === brandUid).name.trim();
  }

  getCategory(category: string) {
    if (this.lang === 'en') {
      return capitalize(category);
    } else {
      return this.categoryItems.find(c => c.name === capitalize(category)).display;
    }
  }

  getSubcategory(subcategory: string) {
    if (this.lang === 'en') {
      return capitalize(subcategory);
    } else {
      return this.subcategoryItems.find(s => s.name === capitalize(subcategory)).display;
    }
  }

  get classModifiers() {
    if (this.modifiers && this.modifiers !== '') {
      return `product-filter_${this.modifiers}`;
    }
    return '';
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
