<div class="brands-grid">
  <div *ngIf="brands.length; else noBrands" class="brands-grid__grid">
    <brand-card
      *ngFor="let brand of brands"
      [brand]=brand
      (onClick)="goToProduct($event)">
    </brand-card>
  </div>
  <ng-template #noBrands>
    <div class="brands-grid__empty-state">
      <div class="brands-grid__empty-state-img">
        <img src="../../../assets/images/products.png" alt="">
      </div>
      <div class="brands-grid__empty-stats-text">
        {{i18nService.data.global.noBrandsFound}}
      </div>
    </div>
  </ng-template>
</div>
