import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/core/models/product';
import { Subscription } from 'rxjs';
import { ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { BrandsService } from 'src/app/core/services/brands.service';
import { Router } from '@angular/router';
import { CartService } from 'src/app/core/services/cart.service';

interface CategoryItem {
  name: string;
  display: string;
}

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input('product') product: Product;
  subscription: Subscription;
  lang: string;
  categoryItems: CategoryItem[] = [];
  subcategoryItems: CategoryItem[] = [];
  isLoadBrandImageLoaded = false;

  constructor(public i18nService: I18nService,
    private router: Router,
    private brandService: BrandsService,
    private cartService: CartService) { }

  ngOnInit() {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.categoryItems = [
          {
            name: 'Cosmetics',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Games',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Others',
            display: this.i18nService.data.navbar.others
          }
        ];
        this.subcategoryItems = [
          {
            name: 'Hair Care',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Skin Care',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Lips Care',
            display: this.i18nService.data.navbar.lipsCare,
          },
          {
            name: 'Body Care',
            display: this.i18nService.data.navbar.bodyCare,
          },
          {
            name: 'Hand Care',
            display: this.i18nService.data.navbar.handCare,
          },
          {
            name: 'Foot Care',
            display: this.i18nService.data.navbar.footCare,
          },
          {
            name: 'Makeup',
            display: this.i18nService.data.navbar.makeup
          },
          {
            name: 'Perfumes',
            display: this.i18nService.data.navbar.perfumes
          },
          {
            name: 'Boy Games',
            display: this.i18nService.data.navbar.boyGames
          },
          {
            name: 'Girl Games',
            display: this.i18nService.data.navbar.girlGames
          },
          {
            name: 'Puzzles',
            display: this.i18nService.data.navbar.puzzles
          },
          {
            name: 'Educational',
            display: this.i18nService.data.navbar.educational
          },
          {
            name: 'Arts & Craft',
            display: this.i18nService.data.navbar.artsAndCrafts
          },
          {
            name: 'Outdoor Games',
            display: this.i18nService.data.navbar.outdoorGames
          },
        ];
      }
    );
  }

  get brandImage() {
    return this.brandService.brands.find(brand => brand.uid === this.product.brand)?.imageUrl;
  }

  get brandName() {
    return this.brandService.brands.find(brand => brand.uid === this.product.brand)?.name;
  }

  showProduct() {
    this.router.navigate(['/main/product'], { queryParams: { product: this.product.uid } });
  }

  getCategory(category: string) {
    if (this.lang === ENGLISH) {
      return category;
    } else {
      return this.categoryItems.find(c => c.name === category).display;
    }
  }

  getSubcategory(subcategory: string) {
    if (this.lang === ENGLISH) {
      return subcategory;
    } else {
      return this.subcategoryItems.find(s => s.name === subcategory).display;
    }
  }

  addToCart() {
    this.cartService.addToCart(this.product);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
