import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/core/models/product';
import { ProductsService } from 'src/app/core/services/products.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-bests-manager',
  templateUrl: './bests-manager.component.html',
  styleUrls: ['./bests-manager.component.scss']
})
export class BestsManagerComponent implements OnInit {
  items$!: Observable<Product[]>;
  loading$!: Observable<boolean>;
  isLoading = false;
  messages: Message[];

  constructor(private productsService: ProductsService) { }

  ngOnInit(): void {
    this.items$ = this.productsService.getBestSellerProduct(true);
    this.loading$ = this.productsService.loading;
    this.messages = [
      {severity:'warn', summary:'Warning', detail:'Only 4 products can display in the best sellers section!'}
    ];
  }

  async remove(product: Product) {
    let newProduct: Product = {
      ...product,
      isBest: false
    }
    await this.productsService.updateProduct(newProduct);
    this.items$ = this.productsService.getBestSellerProduct(true);
    this.loading$ = this.productsService.loading;
  }
}
