import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query, QueryFn } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, catchError, take, tap } from 'rxjs/operators';
import { Product } from '../models/product';
import { InQueryParams } from 'src/app/core/models/query-params';
import { capitalize } from '../utils/capitalize-query-params';
import { StorageService } from 'src/app/core/services/storage.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private _data?: BehaviorSubject<Product[]>;
  public data?: Observable<Product[]>;
  private latestEntry: any;
  private _isDone = false;
  private _loading = new BehaviorSubject<boolean>(false);
  loading: Observable<boolean> = this._loading;
  private _collectionName = 'products';
  private _awesomeCollectionName = 'awesome';

  constructor(
    private afs: AngularFirestore,
    private storageService: StorageService,
    private settingsService: SettingsService
  ) { }

  createProduct(product: Product) {
    return this.afs.collection(this._collectionName).add({ ...product });
  }

  updateProduct(product: Product) {
    if (!product.uid) {
      throw new Error('ProductsService#updateProduct: Product should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(product.uid)
      .update({
        name: product.name,
        nameAr: product.nameAr,
        description: product.description,
        descriptionAr: product.descriptionAr,
        category: product.category,
        subcategory: product.subcategory,
        brand: product.brand,
        imageRef: product.imageRef,
        imageUrl: product.imageUrl,
        count: product.count,
        price: product.price,
        sale: product.sale,
        barcode: product.barcode,
        isSoldOut: product.isSoldOut,
        isBest: product.isBest,
        imageSize: product.imageSize,
        createdAt: product.createdAt,
      }).then(_ => {
        let oldData = this._data.getValue();
        let updatedProductIndex = oldData.findIndex(({ uid }) => product.uid === uid);
        let newData = [
          ...oldData.slice(0, updatedProductIndex),
          product,
          ...oldData.slice(updatedProductIndex + 1),
        ]
        this._data!.next(newData);
      })
  }

  deleteProduct(product: Product) {
    if (!product.uid) {
      throw new Error('ProductsService#deleteProduct: Product should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(product.uid)
      .delete();
  }

  async deleteMultiple(brandUid: string) {
    let db = this.afs.firestore;
    let images = [];
    let size = 0;
    // First perform the query
    return db.collection(this._collectionName).where('brand', '==', brandUid).get()
      .then(querySnapshot => {
            // Once we get the results, begin a batch
            var batch = db.batch();

            querySnapshot.forEach(doc => {
                // For each doc, add a delete operation to the batch
                let product = doc.data() as Product;
                images.push(product.imageUrl);
                size += product.imageSize;
                batch.delete(doc.ref);
            });

            // Commit the batch
            return batch.commit();
      }).then(_ => {
        let storageSetting = this.settingsService.getStorage;
        let value = (storageSetting?.settingValue as number) ?? 0;
        let productSetting = this.settingsService.getProducts;
        let promises = [
          ...images.map(image => this.storageService.deleteFile(image)),
          this.settingsService.updateSetting({
            ...storageSetting!,
            settingValue: value - size
          }),
          this.settingsService.updateSetting({
            ...productSetting!,
            settingValue: (productSetting!.settingValue as number) - images.length
          })
        ];
        return Promise.all(promises);
      });
  }

  get products(): Observable<Product[]> {
    return this.afs
      .collection(this._collectionName)
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Product;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        }))
      );
  }

  getProduct(uid: string) {
    return this.afs
      .collection(this._collectionName)
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map(a => {
          const data = a.payload.data() as Product;
          const uid = a.payload.id;
          return { uid, ...data };
        })
      )
  }

  async getProductPromise(uid: string): Promise<Product> {
    return new Promise((resolve, _) => {
      let subscription = this.getProduct(uid)
        .subscribe(res => {
          subscription.unsubscribe();
          resolve(res);
        });
    });
  }

  getProducts(params: InQueryParams): Observable<Product[]> {
    return this.afs
      .collection(this._collectionName, ref => {
        let query : CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        if (params.category) { query = query.where('category', '==', capitalize(params.category)) };
        if (params.subcategory) { query = query.where('subcategory', '==', capitalize(params.subcategory)) };
        if (params.brand) { query = query.where('brand', '==', params.brand) };
        return query;
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Product;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        })),
        catchError(error => { throw error; })
      );
  }

  searchProducts(search: string, lang: string = 'en', isByBarcode: boolean = false): Observable<Product[]> {
    this._loading.next(true);
    const byEnglish = this.afs
      .collection(this._collectionName, ref => ref
        .orderBy('name')
        .startAt(search)
        .endAt(search + '\uf8ff')
      )
      .snapshotChanges();
    const byArabic = this.afs
      .collection(this._collectionName, ref => ref
        .orderBy('nameAr')
        .startAt(search)
        .endAt(search + '\uf8ff')
      )
      .snapshotChanges();
    const byBarcode = this.afs
      .collection(this._collectionName, ref => ref
        .where('barcode', '==', search)
      )
      .snapshotChanges();

    let obs = lang === 'en' ? byEnglish : byArabic;
    if (isByBarcode) {
      obs = byBarcode;
    }

    return obs
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Product;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        })),
        tap(_ => this._loading.next(false)),
        catchError(error => { throw error; })
      );
  }

  async createAwesomeProduct(product: Product) {
    return await this.afs.collection(this._awesomeCollectionName).add({ ...product });
  }

  async clearAwesome() {
    let db = this.afs.firestore;
    let images = [];
    let size = 0;
    // First perform the query
    return db.collection(this._awesomeCollectionName).get()
      .then(querySnapshot => {
            // Once we get the results, begin a batch
            var batch = db.batch();

            querySnapshot.forEach(doc => {
                // For each doc, add a delete operation to the batch
                let product = doc.data() as Product;
                if (product.imageUrl.startsWith('awesome/')) {
                  images.push(product.imageUrl);
                  size += product.imageSize;
                }
                batch.delete(doc.ref);
            });

            // Commit the batch
            return batch.commit();
      }).then(_ => {
        let storageSetting = this.settingsService.getStorage;
        let value = (storageSetting?.settingValue as number) ?? 0;
        let productSetting = this.settingsService.getProducts;
        let promises = [
          ...images.map(image => this.storageService.deleteFile(image)),
          this.settingsService.updateSetting({
            ...storageSetting!,
            settingValue: value - size
          }),
          this.settingsService.updateSetting({
            ...productSetting!,
            settingValue: (productSetting!.settingValue as number) - images.length
          })
        ];
        return Promise.all(promises);
      });
  }

  getNewProduct() {
    return this.afs
      .collection(this._awesomeCollectionName, ref => ref
        .limit(1)
      )
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Product;
          return { ...data };
        })),
        catchError(error => { throw error; })
      );
  }

  getBestSellerProduct(noLimit: boolean = false) {
    this._loading.next(true);
    return this.afs
      .collection(this._collectionName, ref => {
        if (noLimit) {
          return ref
            .where('isBest', '==', true)
        }
        return ref
          .where('isBest', '==', true)
          .limit(4);
      }
      )
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Product;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        })),
        tap(_ => this._loading.next(false)),
        catchError(error => { throw error; })
      );
  }

  getCollection(ref: string, queryFn?: QueryFn): Observable<any[]> {
    return this.afs.collection(ref, queryFn).snapshotChanges()
    .pipe(
      take(1),
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Product;
        const uid = a.payload.doc.id;
        return { uid, ...data };
      })),
      catchError(error => { throw error; })
    )
  }

  first(params: InQueryParams, limit: number = 10) {
    this._isDone = false;
    this._data = new BehaviorSubject([] as Array<Product>);
    this.data = this._data.asObservable();
    this._loading.next(true);

    this.getCollection(this._collectionName, ref => {
      let query : CollectionReference | Query = ref;
      query = query.orderBy('createdAt', 'desc');
      if (params?.category) { query = query.where('category', '==', capitalize(params.category)) };
      if (params?.subcategory) { query = query.where('subcategory', '==', capitalize(params.subcategory)) };
      if (params?.brand) { query = query.where('brand', '==', params.brand) };
      return query.limit(limit);
    }).subscribe(data => {
      if (data.length > 0) {
        this.latestEntry = data[data.length - 1].createdAt;
      }

      this._data!.next(data);
      this._loading.next(false);
    });
  }

  next(params: InQueryParams, limit: number = 10) {
    if (this._loading.value) { return };
    if (!this._isDone) {
      this._loading.next(true);

      this.getCollection(this._collectionName, ref => {
        let query : CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        if (params?.category) { query = query.where('category', '==', capitalize(params.category)) };
        if (params?.subcategory) { query = query.where('subcategory', '==', capitalize(params.subcategory)) };
        if (params?.brand) { query = query.where('brand', '==', params.brand) };
        return query.startAfter(this.latestEntry).limit(limit);
      }).subscribe(data => {
        if (data.length) {
          this.latestEntry = data[data.length - 1].createdAt;
          this._data?.next(this._data.getValue().concat(data));
        } else {
          this._isDone = true;
        }
        this._loading.next(false);
      });
    }
  }

  get isDone() {
    return this._isDone;
  }
}
