import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { AdminPageComponent } from './admin-page.component';
import { BestsManagerComponent } from './bests-manager/bests-manager.component';
import { BrandsManagerComponent } from './brands-manager/brands-manager.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductsManagerComponent } from './products-manager/products-manager.component';
import { SettingsComponent } from './settings/settings.component';


const routes: Routes = [
  { path: 'admin-portal',
    component: AdminPageComponent,
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'items', component: ProductsManagerComponent },
      { path: 'markers', component: BrandsManagerComponent },
      { path: 'bests', component: BestsManagerComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'settings', component: SettingsComponent },
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
