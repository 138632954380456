<div class="cart-content">
  <div class="cart-content__header">
    <span class="cart-content__header-name">{{i18nService.data.order.cart.name}}</span>
    <span class="cart-content__header-image">{{i18nService.data.order.cart.image}}</span>
    <span class="cart-content__header-price">{{i18nService.data.order.cart.price}}</span>
    <span class="cart-content__header-count">{{i18nService.data.order.cart.count}}</span>
    <span class="cart-content__header-total">{{i18nService.data.order.cart.total}}</span>
  </div>
  <div class="cart-content__body">
    <div *ngFor="let product of products" class="cart-content__body-item">
      <span class="cart-content__body-item-name">
        <span class="cart-content__body-item-label">{{i18nService.data.order.cart.name}}</span>
        {{isEnglish ? product.productName : product.productNameAr}}
      </span>
      <div class="cart-content__body-item-image">
        <a routerLink="/main/product"
          [queryParams]="{product: product.productUID}"
          (click)="clickProduct()"
        >
          <img
            [src]="product.imageUrl"
            [alt]="product.name"
            class="cart-content__product-image"
          />
        </a>
      </div>
      <span class="cart-content__body-item-price">
        <span class="cart-content__body-item-label">{{i18nService.data.order.cart.price}}</span>
        {{product.price | price}}
      </span>
      <div class="cart-content__body-item-count">
        <span class="cart-content__body-item-label">{{i18nService.data.order.cart.count}}</span>
        <number-input
          *ngIf="canChangeCount"
          [value]="product.count"
          [min]="1"
          (OnConfirm)="changeCount($event, product)"
        ></number-input>
        <span *ngIf="!canChangeCount">{{product.count}}</span>
      </div>
      <span class="cart-content__body-item-total">
        <span class="cart-content__body-item-label">{{i18nService.data.order.cart.total}}</span>
        {{product.total | price}}
      </span>
      <div *ngIf="canDelete" class="cart-content__body-item-delete">
        <button style="width:32px" type="button" class="p-button-danger" pButton icon="pi pi-trash" (click)="removeProduct($event, product.productUID)"></button>
      </div>
    </div>
  </div>
  <div class="cart-content__footer">
    <span class="cart-content__footer-totals-label">{{i18nService.data.order.cart.totals}}</span>
    <span class="cart-content__footer-totals-value">{{price | price}}</span>
    <span class="cart-content__footer-count">{{count}}</span>
  </div>
</div>
