<div class="map" [ngStyle]="{'height': height}">
  <div class="map_canvas" [ngStyle]="{'height': height}">
    <iframe
      [src]="controllerSrc"
      width="100%"
      [height]="height"
      style="border:0;"
      allowfullscreen=""
      loading="lazy">
    </iframe>
  </div>
</div>
