import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {
  @Input('value') value = 0;
  @Input('min') min = 0;
  @Input('max') max = 9999999;
  @Output('OnConfirm') OnConfirm: EventEmitter<number> = new EventEmitter<number>();

  // number = new FormControl('');
  number = 0;

  constructor() { }

  ngOnInit(): void {
    // this.number.setValue(this.value);
    this.number = this.value;
  }

  onKey(value: number) {
    if (this.number == null) return;
    if (this.number > this.max) {
      this.number = this.max;
    } else if (this.number < this.min) {
      this.number = this.min;
    }
  }

  onBlurInput() {
    if (this.number == null) {
      this.number = this.value;
    }
  }


  increase() {
    if (this.number !== this.max) {
      this.number++;
    }
  }

  decrease() {
    if (this.number !== this.min) {
      this.number--;
    }
  }

  confirm() {
    this.OnConfirm.emit(this.number);
  }
}
