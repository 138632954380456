import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'location-button',
  templateUrl: './location-button.component.html',
  styleUrls: ['./location-button.component.scss']
})
export class LocationButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showLocation() {
    let latitude = '33.501542';
    let longitude= '35.471904';
    window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
  }
}
