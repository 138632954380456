<div class="orders">
  <div class="orders__container">
    <div class="orders__header">
      <div class="orders__title">
        Orders
      </div>
      <p-button
        (click)="toggleArchived()"
        label="{{showArchived ? 'Show the new orders' : 'Show archived'}}"
        styleClass="p-button-text p-button-success">
      </p-button>
    </div>
    <div class="orders__content">
      <div #orders *ngIf="(items$ | async); let orders;" class="orders__orders-container">
        <div *ngIf="orders.length > 0; else loading" class="orders__list">
          <order-item
            *ngFor="let order of orders"
            [item]="order"
            (onOpen)="openOrder($event)"
            (onRemove)="removeOrder($event)"
            class="orders__order-item"
          >
          </order-item>

          <div style="height: 20px;"></div>
        </div>
        <ng-template #loading>
          <div class="orders__list">
            <div *ngIf="!(loading$ | async);" class="orders__empty-stats">
              <div class="orders__empty-stats-img">
                <img src="../../../assets/images/orders-empty.png" alt="">
              </div>
              <div class="orders__empty-stats-text">
                There are no recent orders to show!
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <mat-progress-spinner
    *ngIf="blockedDocument"
    class="orders__spinner"
    diameter="50"
    mode="indeterminate"
    value="80">
  </mat-progress-spinner>
  <mat-progress-spinner
    *ngIf="loading$ | async;"
    class="orders__spinner"
    diameter="50"
    mode="indeterminate"
    value="80">
  </mat-progress-spinner>
</div>
<p-dialog
  header="Ordered products"
  [(visible)]="selectedCart"
  [modal]="true"
  [style]="{width: '50vw'}"
  [draggable]="false"
  [resizable]="false"
  [autoZIndex]="false"
  (onHide)="hideModal($event)"
>
  <cart-content
    *ngIf="selectedCart"
    [cart]="selectedCart"
  ></cart-content>
</p-dialog>
<p-blockUI [blocked]="blockedDocument"></p-blockUI>
