<footer class="footer js-footer" dir={{dir}}>
  <div class="footer__container">
    <div class="footer__content">
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.quickLinks}}
        </div>
        <a
          *ngFor="let item of cosmeticItems"
          class="footer__section-item"
          (click)=item.onClick()
        >
          {{item.display}}
        </a>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.games}}
        </div>
        <a
          *ngFor="let item of baleaItems"
          class="footer__section-item"
          (click)=item.onClick()
        >
          {{item.display}}
        </a>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.quickContact}}
        </div>
        <span class="footer__section-phone">
          <mat-icon [ngClass]="{'footer__section-phone_lang_ar': lang === 'ar'}" [inline]="true" aria-hidden="false" aria-label="phone" style="font-size: 20px; line-height: 20px; text-align: center; vertical-align: middle; margin-right: 10px;">
            phone
          </mat-icon>
          <span dir="ltr">{{phone}}</span>
        </span>
        <span class="footer__section-email">
          <mat-icon [ngClass]="{'footer__section-email_margin-left_10': lang === 'ar'}" [inline]="true" aria-hidden="false" aria-label="email" style="font-size: 20px; line-height: 20px; text-align: center; vertical-align: middle; margin-right: 10px;">
            email
          </mat-icon>
          <span>{{email}}</span>
        </span>
        <div class="footer__section-social-media">
          <social-media-bar></social-media-bar>
        </div>
      </div>
      <div class="footer__section">
        <div
          class="footer__section-title"
          [ngClass]="{'footer__section-title_right_0': lang === 'ar'}"
        >
          {{i18nService.data.footer.aboutUs}}
        </div>
        <p class="footer__section-about-us">
          {{aboutUs}}
        </p>
        <img src="../../../../assets/images/bblogo.png" alt="" class="footer__logo">
      </div>
    </div>
    <div class="footer__copy-right">
      Aahoo Store © {{currentYear}}. Designed and developed by <a href="https://boomowl.com" target="_blank">boomowl</a>, Lebanon
    </div>
  </div>
</footer>
