import { Component, OnInit, HostListener } from '@angular/core';
import { ProductsService } from 'src/app/core/services/products.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Product } from 'src/app/core/models/product';
import { Observable, Subscription } from 'rxjs';
import { BrandsService } from 'src/app/core/services/brands.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SettingsService } from 'src/app/core/services/settings.service';
import { CartService } from 'src/app/core/services/cart.service';

interface CategoryItem {
  name: string;
  display: string;
}

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  item$: Observable<Product>;
  subscription: Subscription;
  lang: string;
  dir: string;
  categoryItems: CategoryItem[] = [];
  subcategoryItems: CategoryItem[] = [];
  innerWidth: any;
  faWhatsapp = faWhatsapp;
  isLoadBrandImageLoaded = false;

  constructor(private route: ActivatedRoute,
    public i18nService: I18nService,
    private brandService: BrandsService,
    private settingsService: SettingsService,
    private cartService: CartService,
    private productsService: ProductsService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
        this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
        this.categoryItems = [
          {
            name: 'Cosmetics',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Games',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Others',
            display: this.i18nService.data.navbar.others
          }
        ];
        this.subcategoryItems = [
          {
            name: 'Hair Care',
            display: this.i18nService.data.navbar.hairCare,
          },
          {
            name: 'Skin Care',
            display: this.i18nService.data.navbar.skinCare,
          },
          {
            name: 'Lips Care',
            display: this.i18nService.data.navbar.lipsCare,
          },
          {
            name: 'Body Care',
            display: this.i18nService.data.navbar.bodyCare,
          },
          {
            name: 'Hand Care',
            display: this.i18nService.data.navbar.handCare,
          },
          {
            name: 'Foot Care',
            display: this.i18nService.data.navbar.footCare,
          },
          {
            name: 'Makeup',
            display: this.i18nService.data.navbar.makeup
          },
          {
            name: 'Perfumes',
            display: this.i18nService.data.navbar.perfumes
          },
          {
            name: 'Boy Games',
            display: this.i18nService.data.navbar.boyGames
          },
          {
            name: 'Girl Games',
            display: this.i18nService.data.navbar.girlGames
          },
          {
            name: 'Puzzles',
            display: this.i18nService.data.navbar.puzzles
          },
          {
            name: 'Educational',
            display: this.i18nService.data.navbar.educational
          },
          {
            name: 'Arts & Craft',
            display: this.i18nService.data.navbar.artsAndCrafts
          },
          {
            name: 'Outdoor Games',
            display: this.i18nService.data.navbar.outdoorGames
          },
        ];
      }
    );
    this.item$ = this.route.queryParams
      .pipe(
        switchMap(params => {
          return this.productsService.getProduct(params.product)
        })
      );
  }

  get whatsappLink() {
    return `https://wa.me/${this.settingsService.getWhatsappOne.settingValue}`;
  }

  getCategory(category: string) {
    if (this.lang === 'en') {
      return category;
    } else {
      return this.categoryItems.find(c => c.name === category).display;
    }
  }

  getSubcategory(subcategory: string) {
    if (this.lang === 'en') {
      return subcategory;
    } else {
      return this.subcategoryItems.find(s => s.name === subcategory).display;
    }
  }

  getBrandImage(product: Product) {
    return this.brandService.brands.find(brand => brand.uid === product.brand)?.imageUrl;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  addToCart(product: Product) {
    this.cartService.addToCart(product);
  }

  launchUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
