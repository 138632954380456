import { Injectable } from '@angular/core';
import Category from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private iconsPath = './assets/images';
  constructor() { }

  get categories(): Array<Category> {
    return [
      { name: 'Cosmetics', icon: `${this.iconsPath}/cosmetics.svg`,
        subcategories: [
          { name: 'Hair Care', icon: `${this.iconsPath}/hair.svg` },
          { name: 'Skin Care', icon: `${this.iconsPath}/skin.svg` },
          { name: 'Lips Care', icon: `${this.iconsPath}/lips.svg` },
          // { name: 'Mouth Care', icon: `${this.iconsPath}/mouth.svg` },
          { name: 'Body Care', icon: `${this.iconsPath}/body.svg` },
          { name: 'Hand Care', icon: `${this.iconsPath}/hand.svg` },
          { name: 'Foot Care', icon: `${this.iconsPath}/foot.svg` },
          { name: 'Perfumes', icon: `${this.iconsPath}/perfume.svg` },
          { name: 'Makeup', icon: `${this.iconsPath}/makeup.svg` },
        ]
      },
      { name: 'Games', icon: `${this.iconsPath}/games.svg`,
        subcategories: [
          { name: 'Boy Games', icon: `${this.iconsPath}/bowling.svg` },
          { name: 'Girl Games', icon: `${this.iconsPath}/kitchen.svg` },
          { name: 'Puzzles', icon: `${this.iconsPath}/puzzle.svg` },
          { name: 'Educational', icon: `${this.iconsPath}/smart.svg` },
          { name: 'Arts and Crafts', icon: `${this.iconsPath}/paint.svg` },
          { name: 'Outdoor Games', icon: `${this.iconsPath}/bicycle.svg` },
        ]
      },
      { name: 'Others', icon: `${this.iconsPath}/ellipsis.svg`, subcategories: null }
    ];
  }
}
