<div class="new-product-card-loading">
  <div class="new-product-card-loading__img">
    <div class="new-product-card-loading__img-placeholder"></div>
  </div>
  <div class="new-product-card-loading__content">
    <span class="new-product-card-loading__title">
      <div class="loading-mask loading-mask_type_thin"></div>
    </span>
    <span class="new-product-card-loading__title">
      <div class="loading-mask loading-mask_type_thin"></div>
    </span>
    <span class="new-product-card-loading__title">
      <div class="loading-mask loading-mask_type_thin"></div>
    </span>
    <span class="new-product-card-loading__title">
      <div class="loading-mask loading-mask_type_thin"></div>
    </span>
  </div>
</div>
