<div class="dashboard-card">
  <div class="dashboard-card__logo">
    <mat-icon
      class="admin-navbar__item"
      aria-hidden="false"
      aria-label="Categories"
    >
      {{logo}}
    </mat-icon>
  </div>
  <div class="dashboard-card__title">
    {{title}}
  </div>
  <div class="dashboard-card__subtitle">
    - {{subtitle}} -
  </div>
  <div class="dashboard-card__content">
    <ng-content></ng-content>
  </div>
</div>
