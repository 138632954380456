<div id="js-nav" class="admin-navbar">
  <div class="admin-navbar__items">
    <div class="admin-navbar__dashboard-item">
      <div
        class="admin-navbar__item admin-navbar__item_is-dashboard"
        [ngClass]="{'admin-navbar__item_is-active': url === 'dashboard'}"
        (click)="navigateTo('dashboard')"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Dashboard"
          matTooltip="Dashboard"
          [matTooltipPosition]="tooltipPosition"
          [matTooltipShowDelay]=300
          [matTooltipHideDelay]=300
        >
          dashboard
        </mat-icon>
        <span class="admin-navbar__item-label">Dashboard</span>
      </div>
    </div>
    <div
      class="admin-navbar__item"
      [ngClass]="{'admin-navbar__item_is-active': url === 'items'}"
      (click)="navigateTo('items')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Products"
        matTooltip="Your Products"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
        >
        widgets
      </mat-icon>
      <span class="admin-navbar__item-label">Products</span>
    </div>
    <div
      class="admin-navbar__item"
      [ngClass]="{'admin-navbar__item_is-active': url === 'markers'}"
      (click)="navigateTo('markers')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Brands"
        matTooltip="Your Brands"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
      >
        category
      </mat-icon>
      <span class="admin-navbar__item-label">Brands</span>
    </div>
    <div
      class="admin-navbar__item"
      [ngClass]="{'admin-navbar__item_is-active': url === 'bests'}"
      (click)="navigateTo('bests')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Bests"
        matTooltip="Best Sellers"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
      >
        star
      </mat-icon>
      <span class="admin-navbar__item-label">Bests</span>
    </div>
    <div
      class="admin-navbar__item admin-navbar__item-orders"
      [ngClass]="{'admin-navbar__item_is-active': url === 'orders'}"
      (click)="navigateTo('orders')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Orders"
        matTooltip="Orders"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
        [matBadge]="ordersCount"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadgeHidden]="!hasOrders"
      >
        local_shipping
      </mat-icon>
      <span class="admin-navbar__item-label">Orders</span>
    </div>

    <div
      class="admin-navbar__item"
      [ngClass]="{'admin-navbar__item_is-active': url === 'settings'}"
      (click)="navigateTo('settings')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Settings"
        matTooltip="Settings"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
      >
        settings
      </mat-icon>
      <span class="admin-navbar__item-label">Settings</span>
    </div>
  </div>
  <div class="admin-navbar__item" (click)="goToSite()">
    <mat-icon
      aria-hidden="false"
      aria-label="Website"
      matTooltip="Go to the website"
      [matTooltipPosition]="tooltipPosition"
      [matTooltipShowDelay]=300
      [matTooltipHideDelay]=300
      >
      website
    </mat-icon>
    <span class="admin-navbar__item-label">Website</span>
  </div>
  <div class="admin-navbar__logout">
    <div class="admin-navbar__item" (click)="logout()">
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        matTooltip="Logout"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipShowDelay]=300
        [matTooltipHideDelay]=300
      >
        logout
      </mat-icon>
      <span class="admin-navbar__item-label">Logout</span>
    </div>
  </div>
</div>
<p-toast position="top-right" key="notification" preventDuplicates="true">
  <ng-template let-message pTemplate="message">
    <div class="notification" (click)="clickNotification()">
      <i class="pi pi-bell" style="font-size: 3rem"></i>
      <div class="notification__content">
          <h3>{{message.summary}}</h3>
      </div>
    </div>
</ng-template>
</p-toast>
