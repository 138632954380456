import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input('width') width: string = '100px';
  @Input('height') height: string = '20px';
  @Input('skeletonModifier') skeletonModifier: string = 'skeleton_type_thin';

  constructor() { }

  ngOnInit(): void {
  }

}
