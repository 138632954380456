import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storageSize'
})
export class StorageSizePipe implements PipeTransform {

  transform(bytes: number, ...args: unknown[]): unknown {
    if (!bytes || isNaN(bytes) || bytes < 0) {
      return 'Invalid'
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 bytes'
    const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(1)}${sizes[i]}`
  }

}
