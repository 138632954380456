<div class="number-input">
  <div class="number-input__group" dir="ltr">
    <button
      class="number-input__button number-input__button-minus"
      [ngClass]="{'number-input__button-minus_is-disabled': number === min}"
      (click)="decrease()"
      [disabled]="number === min"
    >
      <span class="p-button-icon pi pi-minus" aria-hidden="true"></span>
    </button>
    <input
      class="number-input__quantity"
      [min]="min"
      [max]="max"
      name="quantity"
      type="number"
      [(ngModel)]="number"
      (keyup)="onKey($event)"
      (blur)="onBlurInput()"
    >
    <button
      class="number-input__button number-input__button-plus"
      [ngClass]="{'number-input__button-plus_is-disabled': number === max}"
      [disabled]="number === max"
      (click)="increase()"
    >
      <span class="p-button-icon pi pi-plus" aria-hidden="true"></span>
    </button>
  </div>
  <span
    *ngIf="number !== value"
    (click)="confirm()"
    class="number-input__confirm-button p-button-icon pi pi-check"
    aria-hidden="true">
  </span>
</div>
