import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminPageComponent } from './admin-page.component';
import { SettingsComponent } from './settings/settings.component';
import { OrdersComponent } from './orders/orders.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminNavbarComponent } from './components/admin-navbar/admin-navbar.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardStorageCapacityComponent } from './components/dashboard-storage-capacity/dashboard-storage-capacity.component';
import { DashboardPlansComponent } from './components/dashboard-plans/dashboard-plans.component';
import { NgPrimeModule } from '../ng-prime/ng-prime.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { ProductsManagerComponent } from './products-manager/products-manager.component';
import { BrandsManagerComponent } from './brands-manager/brands-manager.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductItemLoadingComponent } from './components/product-item-loading/product-item-loading.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { HttpClientModule } from '@angular/common/http';
import { BestsManagerComponent } from './bests-manager/bests-manager.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AdminPageComponent,
    AdminNavbarComponent,
    SettingsComponent,
    LoginPageComponent,
    ForgotPasswordPageComponent,
    OrdersComponent,
    DashboardComponent,
    DashboardCardComponent,
    DashboardStorageCapacityComponent,
    DashboardPlansComponent,
    ProductsManagerComponent,
    BrandsManagerComponent,
    ProductItemLoadingComponent,
    ProductItemComponent,
    BestsManagerComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgPrimeModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  exports: [
    ForgotPasswordPageComponent,
    LoginPageComponent
  ]
})
export class AdminModule { }
