import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, Renderer2 } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { Product } from 'src/app/core/models/product';
import { ProductsService } from 'src/app/core/services/products.service';
import { Observable, Subscription } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { InQueryParams } from 'src/app/core/models/query-params';
import Category, { Subcategory } from 'src/app/core/models/category';
import { Brand } from 'src/app/core/models/brand';
import { capitalize, dasherize } from 'src/app/core/utils/capitalize-query-params';
import { BrandsService } from 'src/app/core/services/brands.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Chip } from 'src/app/core/models/chip';

function isSubcategoryRequired(input: FormControl) {
  const category = input.root.get('category');
  if (category && category.value) {
    let value = category.value as Category;
    if (value.subcategories && !input.value) {
      return { required: true };
    }
    return null;
  }
  return null;
}

@Component({
  selector: 'app-products-manager',
  templateUrl: './products-manager.component.html',
  styleUrls: ['./products-manager.component.scss']
})
export class ProductsManagerComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload!: any;
  @ViewChild('specialFileUpload') specialFileUpload!: any;
  @ViewChild('productsFilter') productsFilterElementView: ElementRef;
  @ViewChild('products') productsElementView: ElementRef;
  @ViewChild('container') containerElementView: ElementRef;
  items$!: Observable<Product[]>;
  loading$!: Observable<boolean>;
  matcher = new AppErrorStateMatcher();
  isLoading = false;
  selectedProduct: Product | null = null;
  selectedSpecialProduct: Product | null = null;
  selectedImage: any = null;
  selectedSpecialImage: any = null;
  updateImage = false;
  updateSpecialImage = false;
  showUploadImageError = false;
  displayModal = false;
  displaySpecialModal = false;
  params: InQueryParams = null;
  selectedCategory: Category = null;
  selectedSubcategory: Subcategory = null;
  langSubscription: Subscription;
  subscription: Subscription;
  productsFilterHeight: number;
  sale: number = 0;
  lang = 'en';
  searchByBarcode = false;
  limit = 30;

  productForm = this.fb.group({
    name: ['', Validators.required],
    nameAr: [''],
    description: [''],
    descriptionAr: [''],
    count: [0],
    initialPrice: [, [Validators.required, Validators.min(0)]],
    wholesalePrice: [, [Validators.min(0)]],
    price: [, [Validators.required, Validators.min(0)]],
    sale: [0],
    barcode: [''],
    brand: [null, Validators.required],
    category: [null, Validators.required],
    subcategory: [null, isSubcategoryRequired],
    isSoldOut: [false],
    isBest: [false],
  });

  formatLabel(value: number) {
    return `${value}%`;
  }

  constructor(
    private fb: FormBuilder,
    public categoriesService: CategoriesService,
    private storageService: StorageService,
    private settingsService: SettingsService,
    public productsService: ProductsService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private brandsService: BrandsService,
    private i18nService: I18nService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.langSubscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      });
    this.subscription = this.route.queryParams.subscribe(
      params => {
        this.params = params as InQueryParams;
        if (this.params.search && this.params.search !== '') {
          this.selectedCategory = null;
          this.selectedSubcategory = null;
          this.items$ = this.productsService.searchProducts(this.params.search, this.lang, this.searchByBarcode);
        } else {
          this.selectedCategory = this.params.category ? this.categories.find(cat => cat.name === capitalize(this.params.category)) : null;
          if (this.selectedCategory?.subcategories) {
            this.selectedSubcategory = this.selectedCategory.subcategories.find(sub => sub.name === capitalize(this.params.subcategory));
          } else {
            this.selectedSubcategory = null;
          }
          this.productsService.first(this.params, this.limit);
          this.items$ = this.productsService.data!;
        }
        this.loading$ = this.productsService.loading;
      }
    );
  }

  ngAfterViewChecked() {
    let productsFilterHeight = this.productsFilterElementView.nativeElement.offsetHeight;
    let containerHeight = this.containerElementView.nativeElement.offsetHeight;
    let height = containerHeight - productsFilterHeight - 60;
    if (this.productsElementView) {
      this.renderer.setStyle(this.productsElementView.nativeElement, "height", `${height}px`);
    }
  }

  // get categories() {
  //   return this.categoriesService.categories;
  // }

  brands: Brand[] = [
    ...this.brandsService.brands
  ];

  categories: Category[] = [
    ...this.categoriesService.categories
  ];

  get chips() {
    let chips: Chip[] = [];
    if (this.selectedCategory) {
      chips.push({
        label: this.selectedCategory.name,
        onRemove: () => this.removeCategoryChip()
      })
    }
    if (this.selectedSubcategory) {
      chips.push({
        label: this.selectedSubcategory.name,
        onRemove: () => this.removeSubcategoryChip()
      })
    }
    if (this.params.search) {
      chips.push({
        label: this.params.search.length > 15 ? this.params.search.slice(0, 15) + '...' : this.params.search,
        onRemove: () => this.removeSearchChip()
      })
    }
    return chips;
  }

  get regularSize() {
    return this.settingsService.getMaxRegularImageSize.settingValue ?? '1048576';
  }

  get bigSize() {
    return this.settingsService.getMaxBigImageSize.settingValue ?? '2097152';
  }

  removeCategoryChip() {
    let { category, subcategory, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/admin-portal/items'], { queryParams });
  }

  removeSubcategoryChip() {
    let { subcategory, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/admin-portal/items'], { queryParams });
  }

  removeSearchChip() {
    let { search, ...params } = this.params;
    let queryParams = { ...params };
    this.router.navigate(['/admin-portal/items'], { queryParams });
  }

  onCategoryChange($event) {
    this.productForm.get('subcategory').setValue(null);
  }

  search(search: string) {
    let queryParams = {
      search
    };
    this.router.navigate(['/admin-portal/items'], { queryParams });
  }

  onClickCategory($event) {
    // const category = $event.value;
    // this.selectedCategory = this.selectedCategory?.name === category?.name ? null : category;
    this.selectedSubcategory = null;
    let { brand } = this.params;
    let queryParams = {
      category: dasherize(this.selectedCategory?.name),
      subcategory: null,
      brand
    }
    this.router.navigate(['/admin-portal/items'], { queryParams });

  }

  onClickSubcategory($event) {
    // const subcategories = $event.value;
    // this.selectedSubcategory = this.selectedSubcategory === subcategories ? null : subcategories;
    let { category, brand } = this.params;
    let queryParams = {
      category,
      subcategory: dasherize(this.selectedSubcategory?.name),
      brand
    }
    this.router.navigate(['/admin-portal/items'], { queryParams });
  }

  async createProduct() {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    try {
      if (
        (this.selectedProduct === null && this.selectedImage === null)
        || (this.selectedProduct !== null && this.updateImage  && this.selectedImage === null)
      ) {
        this.showUploadImageError = true;
        this.isLoading = false;
        return;
      }
      let timestamp = + new Date();
      let filePath = this.selectedProduct?.imageRef;
      let url = '';
      let imageSize = this.selectedProduct?.imageSize;
      if (this.selectedProduct === null || this.updateImage) {
        filePath = `products/${timestamp}-${this.selectedImage.name}`;

        let storageSetting = this.settingsService.getStorage;
        let newStorage = (storageSetting?.settingValue as number) ?? 0;
        if (this.selectedProduct) {
          newStorage -= imageSize!;
          await this.storageService.deleteFile(this.selectedProduct.imageUrl);
        }

        let downloadUrl = await this.storageService.uploadFile(filePath, this.selectedImage);
        imageSize = this.selectedImage.size;
        url = await downloadUrl.toPromise();
        await this.settingsService.updateSetting({
          ...storageSetting!,
          settingValue: newStorage + imageSize!
        });
        this.fileUpload.clear();
      }
      if (this.selectedProduct) {
        let product: Product = {
          ...this.selectedProduct,
          name: this.productForm.get('name')?.value,
          nameAr: this.productForm.get('name')?.value,
          description: this.productForm.get('description')?.value,
          descriptionAr: this.productForm.get('description')?.value,
          category: this.productForm.get('category')?.value?.name,
          subcategory: this.productForm.get('category')?.value?.subcategories ? this.productForm.get('subcategory')?.value?.name : null,
          brand: this.productForm.get('brand')?.value?.uid,
          initialPrice: this.productForm.get('initialPrice')?.value,
          wholesalePrice: this.productForm.get('wholesalePrice')?.value,
          price: this.productForm.get('price')?.value,
          sale: this.productForm.get('sale')?.value ?? 0,
          barcode: this.productForm.get('barcode')?.value ?? '',
          isSoldOut: this.productForm.get('isSoldOut')?.value ?? false,
          isBest: this.productForm.get('isBest')?.value ?? false,
          imageUrl: this.updateImage ? url : this.selectedProduct.imageUrl,
          imageRef: filePath!,
          imageSize: imageSize!
        }
        await this.productsService.updateProduct(product);
      } else {
        let product: Product = {
          name: this.productForm.get('name')?.value,
          nameAr: this.productForm.get('name')?.value,
          description: this.productForm.get('description')?.value,
          descriptionAr: this.productForm.get('description')?.value,
          category: this.productForm.get('category')?.value?.name,
          subcategory: this.productForm.get('category')?.value?.subcategories ? this.productForm.get('subcategory')?.value?.name : null,
          brand: this.productForm.get('brand')?.value?.uid,
          count: this.productForm.get('count')?.value,
          initialPrice: this.productForm.get('initialPrice')?.value,
          wholesalePrice: this.productForm.get('wholesalePrice')?.value,
          price: this.productForm.get('price')?.value,
          sale: this.productForm.get('sale')?.value ?? 0,
          barcode: this.productForm.get('barcode')?.value ?? '',
          isSoldOut: this.productForm.get('isSoldOut')?.value ?? false,
          isBest: this.productForm.get('isBest')?.value ?? false,
          imageRef: filePath!,
          imageUrl: url,
          imageSize: imageSize!,
          createdAt: new Date()
        }
        await this.productsService.createProduct(product);
        let productSetting = this.settingsService.getProducts;
        await this.settingsService.updateSetting({
          ...productSetting!,
          settingValue: ++(productSetting!.settingValue as number)
        });
      }

      let editMode = !!this.selectedProduct;
      this.selectedProduct = null;
      this.updateImage = false;
      this.selectedProduct = null;
      this.productForm.reset();
      this.displayModal = false;

      if (!editMode) {
        this.productsService.first(null, this.limit);
      }
      this.items$ = this.productsService.data!;
    } finally {
      this.isLoading = false;
    }
  }

  async createAwesomeProduct() {
    this.isLoading = true;
    try {
      let timestamp = + new Date();
      let filePath = this.selectedSpecialProduct?.imageRef;
      let url = '';
      let imageSize = this.selectedSpecialProduct?.imageSize;
      await this.productsService.clearAwesome();
      if (this.updateSpecialImage) {
        filePath = `awesome/${timestamp}-${this.selectedSpecialImage.name}`;

        let storageSetting = this.settingsService.getStorage;
        let newStorage = (storageSetting?.settingValue as number) ?? 0;
        let downloadUrl = await this.storageService.uploadFile(filePath, this.selectedSpecialImage);
        imageSize = this.selectedSpecialImage.size;
        url = await downloadUrl.toPromise();
        await this.settingsService.updateSetting({
          ...storageSetting!,
          settingValue: newStorage + imageSize!
        });
        this.specialFileUpload.clear();
      }

      let product: Product = {
        ...this.selectedSpecialProduct,
        imageUrl: this.updateSpecialImage ? url : this.selectedSpecialProduct.imageUrl,
        imageRef: filePath,
        imageSize: imageSize
      }
      await this.productsService.createAwesomeProduct(product);
      this.selectedSpecialProduct = null;
      this.updateSpecialImage = false;
      this.selectedSpecialProduct = null;
      this.displaySpecialModal = false;
    } finally {
      this.isLoading = false;
    }
  }

  selectImage($event: any) {
    this.selectedImage = $event.currentFiles[0];
    this.showUploadImageError = false;
  }

  removeImage($event: any) {
    this.selectedImage = null;
  }

  selectSpecialImage($event: any) {
    this.selectedSpecialImage = $event.currentFiles[0];
  }

  removeSpecialImage(_: any) {
    this.selectedSpecialImage = null;
  }

  async deleteProduct(product: Product) {
    this.isLoading = true;
    let deletedProductUrl = product.imageUrl;
    let deletedProductSize = product.imageSize;
    await this.productsService.deleteProduct(product);
    await this.storageService.deleteFile(deletedProductUrl);
    let productSetting = this.settingsService.getProducts;
    let storageSetting = this.settingsService.getStorage;
    await this.settingsService.updateSetting({
      ...productSetting!,
      settingValue: --(productSetting!.settingValue as number)
    });
    await this.settingsService.updateSetting({
      ...storageSetting!,
      settingValue: (storageSetting?.settingValue! as number) - deletedProductSize
    });
    this.productsService.first(null, this.limit);
    this.items$ = this.productsService.data!;
    this.isLoading = false;
  }

  private timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  editProduct(product: Product) {
    this.selectedProduct = product;
    this.updateImage = false;
    this.productForm.get('isSoldOut')?.setValue(this.selectedProduct.isSoldOut);
    this.productForm.get('isBest')?.setValue(this.selectedProduct.isBest);
    this.productForm.get('name')?.setValue(this.selectedProduct.name);
    this.productForm.get('nameAr')?.setValue(this.selectedProduct.name);
    this.productForm.get('description')?.setValue(this.selectedProduct.description);
    this.productForm.get('descriptionAr')?.setValue(this.selectedProduct.description);
    this.productForm.get('initialPrice')?.setValue(this.selectedProduct.initialPrice);
    this.productForm.get('wholesalePrice')?.setValue(this.selectedProduct.wholesalePrice);
    this.productForm.get('price')?.setValue(this.selectedProduct.price);
    this.productForm.get('sale')?.setValue(this.selectedProduct.sale);
    this.productForm.get('count')?.setValue(this.selectedProduct.count);
    this.productForm.get('barcode')?.setValue(this.selectedProduct.barcode);
    let selectedCategory = this.categories.find(({ name }) => name === this.selectedProduct.category);
    this.productForm.get('category')?.setValue(selectedCategory);
    if (selectedCategory.subcategories) {
      let selectedSubcategory = selectedCategory.subcategories.find(({ name }) => name === this.selectedProduct.subcategory);
      this.productForm.get('subcategory')?.setValue(selectedSubcategory);
    }
    let brand = this.brands.find(({ uid }) => uid === this.selectedProduct.brand);
    this.productForm.get('brand')?.setValue(brand);
    this.displayModal = true;
  }

  selectSpecial(product: Product) {
    this.selectedSpecialProduct = product;
    this.updateSpecialImage = false;
    this.displaySpecialModal = true;
  }

  clearSelected() {
    this.selectedProduct = null;
    this.updateImage = false;
    this.selectedImage = null;
    this.fileUpload?.clear();
    this.productForm.reset();
  }

  hideModal($event: any) {
    this.clearSelected();
  }

  hideSpecialModal($event: any) {
    this.selectedSpecialProduct = null;
    this.updateSpecialImage = false;
    this.selectedSpecialImage = null;
    this.specialFileUpload?.clear();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.productsService.next(this.params, this.limit);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
