interface Args {
  productUID: string;
  productName: string;
  productNameAr: string;
  imageUrl: string;
  count: number;
  price: number;
}

export class ProductOrder {
  productUID: string;
  productName: string;
  productNameAr: string;
  imageUrl: string;
  count: number;
  price: number;

  constructor(args: Args) {
    this.productUID = args.productUID;
    this.productName = args.productName;
    this.productNameAr = args.productNameAr;
    this.imageUrl = args.imageUrl;
    this.count = args.count;
    this.price = args.price;
  }

  get total() {
    return this.count * this.price;
  }

  public toJSON() {
    return {
      productUID: this.productUID,
      productName: this.productName,
      productNameAr: this.productNameAr,
      imageUrl: this.imageUrl,
      count: this.count,
      price: this.price,
      total: this.total
    }
  }
}
