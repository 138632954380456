<div class="forgot-password">
  <div class="forgot-password__container">
    <div class="forgot-password__logo"></div>
    <form *ngIf="!sentEmail; else sent" [formGroup]="forgotPasswordForm" class="forgot-password__form">
      <h1 class="forgot-password__title">Reset Password.</h1>
      <p class="forgot-password__info">
        Enter the email associated with your account and we'll send an email with instructions to reset your password.
      </p>

      <mat-form-field appearance="fill" class="forgot-password__email-field">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"
               placeholder="Ex. pat@example.com">
        <mat-error *ngIf="forgotPasswordForm.get('email')?.hasError('email') && !forgotPasswordForm.get('email')?.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="forgotPasswordForm.get('email')?.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="showErrorMessage" class="forgot-password__failed-message">
        Something is wrong. Please try again
      </mat-error>

      <button
        *ngIf="!isLoading; else spinner"
        class="forgot-password__button"
        mat-flat-button color="primary"
        (click)="forgotPassword()"
      >
        Send Instructions
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          class="forgot-password__spinner"
          diameter="30"
          mode="indeterminate"
          value="80">
        </mat-progress-spinner>
      </ng-template>
      <a class="forgot-password__go-back" (click)="goBack()">Back to login</a>
    </form>
    <ng-template #sent>
      <h1>Check your mail</h1>
      <div class="forgot-password__email-icon"></div>
      <button
        class="forgot-password__button"
        mat-flat-button color="primary"
        (click)="goBack()"
      >
        Back to login
      </button>
    </ng-template>
    <div class="forgot-password__powered-by">
      <powered-by></powered-by>
    </div>
  </div>
</div>
