import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from 'src/app/core/models/language';
import { IcTranslationModel } from '../translations/translation-model';
import { Transitions } from '../translations/translations';

export const ENGLISH = 'en';

export const ARABIC = 'ar';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  currentLang = new BehaviorSubject<String>(localStorage.getItem('currentLang') ?? 'ar');
  transitions: IcTranslationModel;

  setLang(lang: string) {
    localStorage.setItem('currentLang', lang);
    this.loadData();
    this.currentLang.next(localStorage.getItem('currentLang'));
  }

  getLang(): Observable<any> {
    return this.currentLang.asObservable();
  }

  loadData() {
    let lang = localStorage.getItem('currentLang') ?? 'ar';
    this.transitions = Transitions[lang as keyof IcTranslationModel];
  }

  get languages(): Language[] {
    return [
      {
        id: 1, flag: '🇺🇸', name: 'English', languageCode: 'en'
      },
      {
        id: 2, flag: '🇱🇧', name: 'اَلْعَرَبِيَّةُ‎', languageCode: 'ar'
      }
    ];
  }

  get data(): IcTranslationModel {
    return this.transitions;
  }
}
