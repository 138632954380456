import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Setting } from '../models/setting';
import { Unit } from '../models/unit';

export const USD = 'USD';

export const LBP = 'LBP';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  _units: Unit[] = [];
  private _unitCollectionName = 'units';
  private _collectionName = 'settings';
  private _storage = 'storage';
  private _brands = 'brands';
  private _products = 'products';
  private _orders = 'orders';
  private _topSectionDescription = 'topSectionDescription';
  private _topSectionDescriptionAr = 'topSectionDescriptionAr';
  private _topSectionTitle = 'topSectionTitle';
  private _topSectionTitleAr = 'topSectionTitleAr';
  private _quote = 'quote';
  private _quoteAr = 'quoteAr';
  private _aboutUs = 'aboutUs';
  private _aboutUsAr = 'aboutUsAr';
  private _map = 'map';
  private _whatsappOne = 'whatsappOne';
  private _whatsappTwo = 'whatsappTwo';
  private _facebook = 'facebook';
  private _instagram = 'instagram';
  private _phone = 'phone';
  private _email = 'email';
  private _orderText = 'orderText';
  private _orderTextAr = 'orderTextAr';
  private _maxRegularImageSize = 'maxRegularImageSize';
  private _maxBigImageSize = 'maxBigImageSize';
  private _settings: Setting[] = [];
  private subscription: Subscription | null = null;

  constructor(private afs: AngularFirestore) { }

  get activeUnit() {
    return this._units.find(({ isActive }) => isActive) ?? this._units.find(({ name }) => name === USD);
  }

  get lbpUnit() {
    return this._units.find(({ name }) => name === LBP);
  }

  get usdUnit() {
    return this._units.find(({ name }) => name === USD);
  }

  async updateUnits(unit: Unit) {
    if (!unit.uid) {
      throw new Error('SettingsService#updateUnits: Unit should contain a uid');
    }
    return this.afs.collection(this._unitCollectionName)
      .doc(unit.uid)
      .update({
        name: unit.name,
        nameAr: unit.nameAr,
        rate: unit.rate,
        symbol: unit.symbol,
        isActive: unit.isActive
      })
  }

  async loadUnits() {
    return new Promise((resolve, _) => {
      this.afs
        .collection(this._unitCollectionName)
        .snapshotChanges()
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Unit;
            const uid = a.payload.doc.id;
            return { uid, ...data };
          }))
        )
        .subscribe(res => {
          this._units = res;
          resolve(true);
        });
    });
  }

  get settings() {
    return this._settings;
  }

  get getStorage() {
    return this.settings.find(({ settingName }) => settingName === this._storage);
  }

  get getBrands() {
    return this.settings.find(({ settingName }) => settingName === this._brands);
  }

  get getProducts() {
    return this.settings.find(({ settingName }) => settingName === this._products);
  }

  get getOrders() {
    return this.settings.find(({ settingName }) => settingName === this._orders);
  }

  get getTopSectionDescription() {
    return this.settings.find(({ settingName }) => settingName === this._topSectionDescription);
  }

  get getTopSectionDescriptionAr() {
    return this.settings.find(({ settingName }) => settingName === this._topSectionDescriptionAr);
  }

  get getTopSectionTitle() {
    return this.settings.find(({ settingName }) => settingName === this._topSectionTitle);
  }

  get getTopSectionTitleAr() {
    return this.settings.find(({ settingName }) => settingName === this._topSectionTitleAr);
  }

  get getQuote() {
    return this.settings.find(({ settingName }) => settingName === this._quote);
  }

  get getQuoteAr() {
    return this.settings.find(({ settingName }) => settingName === this._quoteAr);
  }

  get getAboutUs() {
    return this.settings.find(({ settingName }) => settingName === this._aboutUs);
  }

  get getAboutUsAr() {
    return this.settings.find(({ settingName }) => settingName === this._aboutUsAr);
  }

  get getMap() {
    return this.settings.find(({ settingName }) => settingName === this._map);
  }

  get getWhatsappOne() {
    return this.settings.find(({ settingName }) => settingName === this._whatsappOne);
  }

  get getWhatsappTwo() {
    return this.settings.find(({ settingName }) => settingName === this._whatsappTwo);
  }

  get getFacebook() {
    return this.settings.find(({ settingName }) => settingName === this._facebook);
  }

  get getInstagram() {
    return this.settings.find(({ settingName }) => settingName === this._instagram);
  }

  get getPhone() {
    return this.settings.find(({ settingName }) => settingName === this._phone);
  }

  get getEmail() {
    return this.settings.find(({ settingName }) => settingName === this._email);
  }

  get getOrderText() {
    return this.settings.find(({ settingName }) => settingName === this._orderText);
  }

  get getOrderTextAr() {
    return this.settings.find(({ settingName }) => settingName === this._orderTextAr);
  }

  get getMaxRegularImageSize() {
    return this.settings.find(({ settingName }) => settingName === this._maxRegularImageSize);
  }

  get getMaxBigImageSize() {
    return this.settings.find(({ settingName }) => settingName === this._maxBigImageSize);
  }

  async loadSettings() {
    return new Promise((resolve) => {
      this.subscription = this.afs
        .collection(this._collectionName)
        .snapshotChanges()
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Setting;
            const uid = a.payload.doc.id;
            return { uid, ...data };
          }))
        )
        .subscribe(res => {
          this._settings = res;
          resolve(true);
        });
    });
  }

  updateSetting(setting: Setting) {
    if (!setting.uid) {
      throw new Error('SettingsService#updateSetting: Setting should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(setting.uid)
      .update({
        settingValue: setting.settingValue
      })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
