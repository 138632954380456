import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { I18nService } from '../core/services/i18n.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Location } from "@angular/common";
import { SettingsService } from '../core/services/settings.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  langDirection: string;
  faWhatsapp = faWhatsapp;
  showWpButtons = false;
  routeSubscription: Subscription;
  innerWidth: any;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(public i18nService: I18nService,
    private settingsService: SettingsService,
    public router: Router, private location: Location) {
    if (localStorage.getItem('currentLang') == null) {
      localStorage.setItem('currentLang', 'ar');
    }
    this.i18nService.loadData();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    this.i18nService.getLang().subscribe(lang => {
      if (lang === 'ar') {
        this.langDirection = 'rtl';
      } else {
        this.langDirection = 'ltr';
      }
    });
  }

  get whatsappOneLink() {
    return `https://wa.me/${this.settingsService.getWhatsappOne.settingValue}`;
  }

  get whatsappTwoLink() {
    return `https://wa.me/${this.settingsService.getWhatsappTwo.settingValue}`;
  }

  get sameWhatsapp() {
    return this.whatsappOneLink === this.whatsappTwoLink;
  }

  get showFAB() {
    return this.router.url.split('?')[0] !== '/main/product';
  }

  toggleFabButtons() {
    if (this.sameWhatsapp) {
      this.launchUrl(this.whatsappOneLink);
    } else {
      this.showWpButtons = !this.showWpButtons;
    }
  }

  launchUrl(url: string) {
    window.open(url, '_blank');
    this.showWpButtons = !this.showWpButtons;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
