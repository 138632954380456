import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent implements OnInit {
  @Input('is-clickable') isClickable = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    if (this.isClickable) {
      window.open('https://www.boomowl.com', '_blank');
    }
  }
}
