import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-storage-capacity',
  templateUrl: './dashboard-storage-capacity.component.html',
  styleUrls: ['./dashboard-storage-capacity.component.scss']
})
export class DashboardStorageCapacityComponent implements OnInit {
  chartSize = 300;
  subscription: Subscription;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public settingsService: SettingsService
  ) {
    this.subscription = breakpointObserver.observe([
      '(min-width: 1200px)'
    ]).subscribe(result => {
      this.chartSize = result.matches ? 300 : 250;
    });
  }

  ngOnInit(): void {
    this.chartSize = this.breakpointObserver.isMatched('(min-width: 1200px)') ? 300 : 200;
  }

  get valueColor() {
    if (+this.storage > 4.2) {
      return 'red'
    } else if (+this.storage > 3) {
      return 'orange'
    }
    return 'lightgreen';
  }

  get textColor() {
    if (+this.storage < 0.01) {
      return 'lightgray'
    }
    return this.valueColor;
  }

  get storage() {
    let bytes = (this.settingsService.getStorage?.settingValue as number) ?? 0;
    let gigabytes = bytes / (1024 * 1024 * 1024);
    return (Math.round(gigabytes * 100) / 100).toFixed(2);
  }

  get size() {
    return (this.settingsService.getStorage?.settingValue as number) ?? 0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
