<div class="brands">
  <div class="brands__header">
    <div class="brands__header-title">
      {{i18nService.data.global.brandsTitle}}
    </div>
    <div class="brands__header-subtitle">
      {{i18nService.data.global.brandsSubtitle}}
    </div>
  </div>
  <div class="brands-grid">
    <brands-grid [brands]="brands" (onClickBrand)="goToProduct($event)" ></brands-grid>
  </div>
</div>

