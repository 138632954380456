<div class="social-media-bar">
  <fa-icon
    class="social-media-bar__icon social-media-bar__icon_is-facebook"
    [icon]="faFacebookF"
    size="2x"
    (click)="launchUrl(facebookLink)"
  >
  </fa-icon>
  <fa-icon
    class="social-media-bar__icon social-media-bar__icon_is-whatsapp"
    [icon]="faWhatsapp"
    size="2x"
    (click)="launchUrl(whatsappLink)"
  >
  </fa-icon>
  <fa-icon
    class="social-media-bar__icon social-media-bar__icon_is-instagram"
    [icon]="faInstagram"
    size="2x"
    (click)="launchUrl(instagramLink)"
  >
  </fa-icon>
</div>
