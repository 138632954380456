
<div class="products">
  <!-- <div class="products__header">
    <div class="products__header-chips">
      <app-chips-bar [chips]="chips"></app-chips-bar>
    </div>
  </div> -->
  <div *ngIf="(items$ | async); let products;" class="products__container">
    <div *ngIf="products.length > 0; else loading" class="product__grid">
      <product-card
        *ngFor="let product of products"
        [product]=product
      >
      </product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
      <animated-product-card *ngIf="loading$ | async;"></animated-product-card>
    </div>
    <ng-template #loading>
      <div *ngIf="loading$ | async;" class="product__grid">
        <animated-product-card *ngFor="let item of [].constructor(8)"></animated-product-card>
      </div>
      <div *ngIf="!(loading$ | async);" class="products__container">
        <div class="products__empty-stats">
          <div class="products__empty-stats-img">
            <img src="../../../assets/images/no-products-found.png" alt="">
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <button
    *ngIf="!(loading$ | async) && !isDone"
    class="products__show-more-button"
    mat-flat-button
    (click)="fetchMore()"
  >
    Show More
  </button>
</div>
