import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  matcher = new AppErrorStateMatcher();

  sentEmail = false;

  isLoading = false;

  showErrorMessage = false;

  mode: ProgressSpinnerMode = 'determinate';

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  forgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.showErrorMessage = false;
    this.isLoading = true;
    let email = `${this.forgotPasswordForm.get('email')?.value}`.trim();
    this.authService
      .forgotPassword(email)
      .then(_ => this.sentEmail = true)
      .catch(err => {
        this.showErrorMessage = true;
        console.error('Something is wrong:', err.message);
      })
      .finally(
        () => this.isLoading = false
      );
  }

  goBack() {
    this.router.navigate(['/login']);
  }

}
