import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Product } from 'src/app/core/models/product';
import { BrandsService } from 'src/app/core/services/brands.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @Input('product') product!: Product;
  @Input('isRunning') isRunning: boolean = false;
  @Input('isEditable') isEditable: boolean = true;
  @Input('canDelete') canDelete: boolean = true;
  @Input('deleteLabel') deleteLabel: string = 'Delete';
  @Input('deleteDialogMessage') deleteDialogMessage: string = 'Are you sure that you want to delete this product?';
  @Input('canAddAsSpecial') canAddAsSpecial: boolean = true;
  @Output('onEdit') onEdit: EventEmitter<Product> = new EventEmitter<Product>();
  @Output('onAddAsSpecial') onAddAsSpecial: EventEmitter<Product> = new EventEmitter<Product>();
  @Output('onDelete') onDelete: EventEmitter<Product> = new EventEmitter<Product>();
  isImgLoaded = false;
  displayFullScreen = false;
  faWhatsapp = faWhatsapp;
  link = "whatsapp://send?text=www.google.com";


  constructor(
    private confirmationService: ConfirmationService,
    private brandsServices: BrandsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  get galleria() {
    return [this.product];
  }

  get productLink() {
    return `https://aahoostore.com/main/product?product=${this.product.uid}`;
  }

  get whatsappProductLink() {
    return this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?text=${this.productLink}`);
  }

  addAsSpecial() {
    this.onAddAsSpecial.emit(this.product);
  }

  edit() {
    this.onEdit.emit(this.product);
  }

  get brand() {
    return this.brandsServices.brands.find(({ uid }) => this.product.brand === uid);
  }

  delete(event: Event) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: this.deleteDialogMessage,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete.emit(this.product);
      },
      reject: () => {}
    });
  }

  clickImage() {
    window.open(this.productLink, '_black');
  }
}
