<!-- <mat-accordion class="order-item" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{item.fullName}}
      </mat-panel-title>
      <mat-panel-description>
        Price {{cart.price | price}}
        <mat-icon>local_shipping</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <cart-content
      [cart]="cart"
      [canDelete]="false"
      [canChangeCount]="false"
    ></cart-content>

  </mat-expansion-panel>
</mat-accordion> -->

<div class="order-item">
  <span class="order-item__name">
    <span class="order-item__name-value">
      {{item.fullName}}
    </span>
    <span class="order-item__date">
      {{createAt | date: 'medium'}}
    </span>
  </span>
  <span class="order-item__phone">{{item.phone}}</span>
  <span class="order-item__address">Address: <strong>{{item.address}}</strong>, {{item.state}}, {{item.country}}</span>
  <div class="order-item__price">
    <span class="order-item__price-text">Ordering </span>
    <span class="order-item__price-value">{{cart.count}}</span>
    <span *ngIf="cart.count === 1" class="order-item__price-text"> product for </span>
    <span *ngIf="cart.count > 1" class="order-item__price-text"> products for </span>
    <span class="order-item__price-value">{{cart.price | price}}</span>
  </div>
  <button
    pButton
    pRipple
    type="button"
    label="Open"
    class="order-item__open p-button-success"
    (click)="open()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Remove"
    class="order-item__done p-button-danger"
    (click)="remove($event)"
  ></button>
</div>
<!-- <p-confirmPopup></p-confirmPopup> -->
