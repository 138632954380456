import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { I18nService } from './core/services/i18n.service';
import { BrandsService } from './core/services/brands.service';
import { SettingsService } from './core/services/settings.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './reuse-strategy';
import { NgPrimeModule } from './ng-prime/ng-prime.module';
import { MaterialModule } from './material/material.module';
import { AdminModule } from './admin/admin.module';
import { RouterModule } from '@angular/router';

export function init_app(i18nService: I18nService) {
  return () => i18nService.loadData();
}

export function load_brands(brandService: BrandsService) {
  return () => brandService.loadBrands();
}

export function load_units(settingsService: SettingsService) {
  return () => settingsService.loadUnits();
}

export function load_settings(settingsService: SettingsService) {
  return () => settingsService.loadSettings();
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    RouterModule,
    AdminModule,
    PagesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    FontAwesomeModule,
    NgPrimeModule,
    MaterialModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [I18nService], multi: true },
    { provide: APP_INITIALIZER, useFactory: load_brands, deps: [BrandsService], multi: true },
    { provide: APP_INITIALIZER, useFactory: load_units, deps: [SettingsService], multi: true },
    { provide: APP_INITIALIZER, useFactory: load_settings, deps: [SettingsService], multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
