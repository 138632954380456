<div class="products-filter" [ngClass]="classModifiers">
  <button
    class="products-filter__arrow"
    type="button"
    (click)="scrollLeft()"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="search__button-icon"
    >
      arrow_left
    </mat-icon>
  </button>
  <div
    #scrollContainer
    class="products-filter__categories-filter-container"
    (mousedown)="startDragging($event)"
    (mousemove)="onDragging($event)"
    (mouseup)="stopDragging()"
    (mouseleave)="stopDragging()"
  >
    <div *ngFor="let item of categories" class="products-filter__categories-filter-sub-container">
      <div
        class="products-filter__categories-filter-item products-filter__categories-filter-item_is-category"
        [ngClass]="{'products-filter__categories-filter-item_is-selected': selectedCategory?.name === item.name}"
        (click)="clickCategory(item)">
        <img [src]=item.icon alt="">
        <span class="products-filter__categories-filter-item-label">{{item.name}}</span>
      </div>
      <div
        *ngFor="let subItem of item.subcategories"
        class="products-filter__categories-filter-item"
        [ngClass]="{'products-filter__categories-filter-item_is-selected': selectedSubcategory?.name === subItem.name}"
        (click)="clickSubcategory(subItem)">
        <img [src]=subItem.icon alt="">
        <span class="products-filter__categories-filter-item-label">{{subItem.name}}</span>
      </div>
    </div>
  </div>
  <button
    class="products-filter__arrow"
    type="button"
    (click)="scrollRight()"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="search__button-icon"
    >
      arrow_right
    </mat-icon>
  </button>
</div>
