import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-plans',
  templateUrl: './dashboard-plans.component.html',
  styleUrls: ['./dashboard-plans.component.scss']
})
export class DashboardPlansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
