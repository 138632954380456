import { Component, OnInit, ViewChild } from '@angular/core';
import { Brand } from 'src/app/core/models/brand';
import { BrandsService } from 'src/app/core/services/brands.service';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-brands-manager',
  templateUrl: './brands-manager.component.html',
  styleUrls: ['./brands-manager.component.scss']
})
export class BrandsManagerComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload!: any;
  displayModal = false;
  selectedBrand: Brand | null = null;
  updateImage = false;
  selectedImage: any = null;
  matcher = new AppErrorStateMatcher();
  showUploadImageError = false;
  loading$!: Observable<boolean>;
  isLoading = false;

  brandForm = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private brandService: BrandsService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private productsService: ProductsService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
  }

  get brands() {
    return this.brandService.brands;
  }

  get regularSize() {
    return this.settingsService.getMaxRegularImageSize.settingValue ?? '1048576';
  }

  selectImage($event: any) {
    this.selectedImage = $event.currentFiles[0];
    this.showUploadImageError = false;
  }

  removeImage($event: any) {
    this.selectedImage = null;
  }

  async createBrand() {
    if (this.brandForm.invalid) {
      this.brandForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    try {
      if (
        (this.selectedBrand === null && this.selectedImage === null)
        || (this.selectedBrand !== null && this.updateImage  && this.selectedImage === null)
      ) {
        this.showUploadImageError = true;
        this.isLoading = false;
        return;
      }
      let timestamp = + new Date();
      let filePath = this.selectedBrand?.imageRef;
      let url = '';
      let imageSize = this.selectedBrand?.imageSize;
      if (this.selectedBrand === null || this.updateImage) {
        filePath = `brands/${timestamp}-${this.selectedImage.name}`;

        let storageSetting = this.settingsService.getStorage;
        let newStorage = (storageSetting?.settingValue as number) ?? 0;
        if (this.selectedBrand) {
          newStorage -= imageSize!;
          await this.storageService.deleteFile(this.selectedBrand.imageUrl);
        }

        let downloadUrl = await this.storageService.uploadFile(filePath, this.selectedImage);
        imageSize = this.selectedImage.size;
        url = await downloadUrl.toPromise();
        await this.settingsService.updateSetting({
          ...storageSetting!,
          settingValue: newStorage + imageSize!
        });
        this.fileUpload.clear();
      }
      if (this.selectedBrand) {
        let brand: Brand = {
          ...this.selectedBrand,
          name: this.brandForm.get('name')?.value,
          imageUrl: this.updateImage ? url : this.selectedBrand.imageUrl,
          imageRef: filePath!,
          imageSize: imageSize!
        }
        await this.brandService.updateBrand(brand);
      } else {
        let brand: Brand = {
          name: this.brandForm.get('name')?.value,
          imageRef: filePath!,
          imageUrl: url,
          imageSize: imageSize!
        }
        await this.brandService.createBrand(brand);
        let brandSetting = this.settingsService.getBrands;
        await this.settingsService.updateSetting({
          ...brandSetting!,
          settingValue: ++(brandSetting!.settingValue as number)
        });
      }

      this.selectedBrand = null;
      this.updateImage = false;
      this.selectedBrand = null;
      this.brandForm.reset();
      this.displayModal = false;

      await this.brandService.loadBrands();
    } finally {
      this.isLoading = false;
    }
  }

  editBrand(brand: Brand) {
    this.selectedBrand = brand;
    this.updateImage = false;
    this.brandForm.get('name')?.setValue(this.selectedBrand.name);
    this.displayModal = true;
  }

  deleteBrand(event: Event) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Are you sure that you want to delete this brand? All products related will be deleted too!',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.delete();
      },
      reject: () => {}
    });
  }

  async delete() {
    let brand = this.selectedBrand;
    this.isLoading = true;
    let deletedBrandUrl = brand.imageUrl;
    let deletedBrandSize = brand.imageSize;
    await this.productsService.deleteMultiple(brand.uid);
    await this.brandService.deleteBrand(brand);
    await this.storageService.deleteFile(deletedBrandUrl);
    let brandSetting = this.settingsService.getBrands;
    let storageSetting = this.settingsService.getStorage;
    await this.settingsService.updateSetting({
      ...brandSetting!,
      settingValue: --(brandSetting!.settingValue as number)
    });
    await this.settingsService.updateSetting({
      ...storageSetting!,
      settingValue: (storageSetting?.settingValue! as number) - deletedBrandSize
    });
    await this.brandService.loadBrands();
    this.isLoading = false;
    this.selectedBrand = null;
    this.updateImage = false;
    this.selectedBrand = null;
    this.brandForm.reset();
    this.displayModal = false;
  }

  clearSelected() {
    this.selectedBrand = null;
    this.updateImage = false;
    this.selectedImage = null;
    this.fileUpload?.clear();
    this.brandForm.reset();
  }

  hideModal($event: any) {
    this.clearSelected();
  }
}
