<div class="product" dir={{dir}}>
  <div *ngIf="item$ | async; let product; else loading">
    <div *ngIf="product.name; else noProduct" class="product__container">
      <div class="product__img">
        <img [src]=product.imageUrl alt="">
      </div>
      <div class="product__data">
        <div class="product__title">
          {{lang === 'en' ? product.name : product.nameAr}}
        </div>
        <span class="product__category">
          {{getCategory(product.category)}} {{product.subcategory ? "-" : ""}} {{product.subcategory ? getSubcategory(product.subcategory) : ""}}
        </span>
        <p class="product__description">
          {{lang === 'en' ? product.description : product.descriptionAr}}
        </p>
        <span *ngIf="product.sale === 0" class="product__price">
          {{product.price | price}}
        </span>
        <div *ngIf="product.sale !== 0" class="product__price-with-sale">
          <span class="product__old-price">
            {{product.price | price}}
          </span>
          <span class="product__price">
            {{product.price | sale: product.sale}}
          </span>
        </div>
        <img
          [hidden]="!isLoadBrandImageLoaded"
          class="product__brand"
          src={{getBrandImage(product)}} alt=""
          (load)="isLoadBrandImageLoaded = true"
        >
        <img *ngIf="!isLoadBrandImageLoaded" class="product__brand" src="assets/images/loadbrand.png" alt="">

        <div *ngIf="!product.isSoldOut" (click)="addToCart(product)" class="product__add-to-cart">
          <div class="product__add-to-cart-icon"></div>
          {{i18nService.data.productPage.addToCart}}
        </div>

        <div *ngIf="innerWidth < 501" class="product__contact-us" (click)="launchUrl(whatsappLink)">
          <span>{{i18nService.data.global.contactUs}}</span>
          <fa-icon
            class="product__whatsapp product__whatsapp_first"
            [icon]="faWhatsapp"
            size="2x"
            ></fa-icon>
        </div>
      </div>
    </div>
    <ng-template #noProduct>
      <div class="product__empty-stats">
        <div class="product__empty-stats-img">
          <img src="../../../assets/images/products.png" alt="">
        </div>
        <div class="product__empty-stats-text">
          {{i18nService.data.global.noProductFound}}
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #loading>
    <div class="product__container_is-loading">
      {{i18nService.data.global.loading}}
    </div>
  </ng-template>
</div>
