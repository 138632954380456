import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cart } from '../models/cart';
import { Product } from '../models/product';
import { ProductOrder } from '../models/product-order';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private _key = 'cart';
  private _cart = new BehaviorSubject<Cart>(this.storedCart);

  constructor(private productsService: ProductsService) { }

  get cart$(): Observable<Cart> {
    return this._cart.asObservable();
  }

  get cart() {
    return this._cart.value;
  }

  get storedCart() {
    let cart = new Cart();
    let stored = JSON.parse(localStorage.getItem(this._key));
    if (stored) {
      let productOrders = [...JSON.parse(stored.productOrders)].map(pOrder => new ProductOrder({
        productUID: pOrder.productUID,
        productName: pOrder.productName,
        productNameAr: pOrder.productNameAr,
        imageUrl: pOrder.imageUrl,
        price: pOrder.price,
        count: pOrder.count
      }))
      cart.productOrders = productOrders;
    }
    return cart;
  }

  set storedCart(cart: Cart) {
    localStorage.setItem(this._key, JSON.stringify(cart));
    this._cart.next(cart);
  }

  clearCart() {
    localStorage.removeItem(this._key);
    this._cart.next(new Cart());
  }

  addToCart(product: Product) {
    let cart = this._cart.value;
    if (!cart) {
      cart = new Cart();
    }
    let index = cart.productOrders.findIndex(({ productUID }) => productUID === product.uid);
    let price = (product.price * (1 - (product.sale / 100)));
    if (index < 0) {
      cart.productOrders = [...cart.productOrders, new ProductOrder({
        productUID: product.uid,
        productName: product.name,
        productNameAr: product.nameAr,
        imageUrl: product.imageUrl,
        price: price,
        count: 1
      })];
    } else {
      if (cart.productOrders[index].price !== price) {
        // In case the price of the product was changed
        cart.productOrders[index].price = price;
      }
      cart.productOrders[index].count++;
    }
    this.storedCart = cart;
  }

  changeProductCount(productOrder: ProductOrder, count: number) {
    if (count < 1) {
      throw Error('Count must be great than one');
    }
    let cart = this._cart.value;
    let index = cart.productOrders.findIndex(({ productUID }) => productUID === productOrder.productUID);
    if (index >= 0) {
      if (cart.productOrders[index].price !== productOrder.price) {
        // In case the price of the product changed
        cart.productOrders[index].price = productOrder.price;
      }
      cart.productOrders[index].count = count;
    }
    this.storedCart = cart;
  }

  removeFromCard(uid: string) {
    let cart = this._cart.value;
    if (cart) {
      cart.productOrders = [...cart.productOrders.filter(({ productUID }) => productUID !== uid)];
    }
    this.storedCart = cart;
  }

  async updateInBG() {
    if (sessionStorage.getItem('update') ?? false) return;
    let cart = this.cart;
    let toRemove = [];
    for (let productOrder of cart.productOrders) {
      let product = await this.productsService.getProductPromise(productOrder.productUID);
      if (product) {
        let price = (product.price * (1 - (product.sale / 100)));
        productOrder.productName = product.name;
        productOrder.productNameAr = product.nameAr;
        productOrder.price = price;
        productOrder.imageUrl = product.imageUrl;
      } else {
        toRemove.push(productOrder.productUID);
      }
    }
    for (let uid of toRemove) {
      cart.productOrders = [...cart.productOrders.filter(({ productUID }) => productUID !== uid)];
    }
    this.storedCart = cart;
    sessionStorage.setItem('update', JSON.stringify(true));
  }
}
