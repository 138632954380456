<div class="admin">
  <div *ngIf="largeScreen; else smallScreen" class="admin__container">
    <div class="admin__navbar">
      <admin-navbar></admin-navbar>
    </div>
    <div class="admin__content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <ng-template #smallScreen>
    <div class="admin__container">
      <span class="admin__small-screen">
        This feature is not available on small screens. Please use a larger screen to continue.
      </span>
    </div>
  </ng-template>
</div>
<p-toast ></p-toast>
