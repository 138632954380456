import { ProductOrder } from "./product-order";

export class Cart {
  productOrders: ProductOrder[];

  constructor() {
    this.productOrders = [];
  }

  get price() {
    return this.productOrders.reduce((total: number, current: ProductOrder) => total + current.total, 0);
  }

  get count() {
    return this.productOrders.reduce((total: number, current: ProductOrder) => total + current.count, 0);
  }

  public toJSON() {
    return {
      productOrders: JSON.stringify(this.productOrders),
      price: this.price,
      count: this.count
    }
  }
}
