import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output('onSearch') onSearch: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('search') searchElementView: ElementRef;
  @Input('clearAfterSearch') clear = true;
  @Input('isHidden') isHidden = false;

  constructor(public i18nService: I18nService) { }

  ngOnInit(): void {
  }

  onSearchClick(value: string) {
    this.onSearch.emit(value);
    if (this.clear){
      this.searchElementView.nativeElement.value = '';
    }
  }
}
