<form
  class="search"
  (keydown.enter)="$event.preventDefault()"
  role="search"
  [ngClass]="{'search-hidden': isHidden}"
>
  <input
    #search
    id="search"
    class="search__input"
    [value]
    type="search"
    placeholder={{i18nService.data.global.search}}
    (keyup.enter)="onSearchClick(search.value)"
  />
  <button
    (click)="onSearchClick(search.value)"
    class="search__button"
    type="button"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="search__button-icon"
    >
      search
    </mat-icon>
  </button>
</form>
