import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Brand } from 'src/app/core/models/brand';

@Component({
  selector: 'brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.scss']
})
export class BrandCardComponent implements OnInit {
  @Input('brand') brand: Brand;
  @Output('onClick') onClick: EventEmitter<Brand> = new EventEmitter<Brand>();

  constructor() { }

  ngOnInit(): void {
  }

  onCardClick() {
    this.onClick.emit(this.brand);
  }
}
