<div class="brands-manager">
  <div #container class="brands-manager__container">
    <div class="brands-manager__header">
      <div class="brands-manager__title">
        Brands
      </div>
      <p-button
        icon="pi pi-plus"
        (click)="displayModal=true"
        label="New Brand"
        styleClass="p-button-text p-button-success">
      </p-button>
    </div>

    <div class="brands-manager__content">
      <p-dialog
        header="{{selectedBrand ? 'Edit Brand.' : 'New Brand.'}}"
        [(visible)]="displayModal"
        [modal]="true"
        [style]="{width: '50vw'}"
        [draggable]="false"
        [resizable]="false"
        (onHide)="hideModal($event)"
      >
        <form id="brand-form" [formGroup]="brandForm" class="brands-manager__form">

         <mat-form-field appearance="fill" class="brands-manager__name-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" [errorStateMatcher]="matcher"
                  placeholder="Brand name">
            <mat-error *ngIf="brandForm.get('name')?.hasError('required')">
              Brand name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div class="brands-manager__file-upload">
            <h3>Brand Image</h3>
            <mat-checkbox
              *ngIf="selectedBrand"
              color="primary"
              class="brands-manager__update-image-checkbox"
              [(ngModel)]="updateImage"
              [ngModelOptions]="{standalone: true}"
            >
              Want to update the image
            </mat-checkbox>
            <p-fileUpload
              #fileUpload
              *ngIf="!selectedBrand || updateImage"
              accept="image/*"
              [maxFileSize]="regularSize"
              [customUpload]="true"
              [showUploadButton]="false"
              [showCancelButton]="false"
              (onSelect)="selectImage($event)"
              (onRemove)="removeImage($event)"
            ></p-fileUpload>
            <mat-error *ngIf="showUploadImageError">
              Brand image is <strong>required</strong>
            </mat-error>
          </div>
          <div class="brands-manager__actions">
            <button
              *ngIf="!isLoading && selectedBrand"
              pButton
              pRipple
              type="button"
              label="Delete"
              class="p-button-danger p-button-text"
              (click)="deleteBrand($event)"
            ></button>
            <p-button
              *ngIf="!isLoading; else spinner"
              icon="pi pi-check"
              form="brand-form"
              (onClick)="createBrand()"
              [disabled]="!brandForm.dirty && selectedImage === null"
              label="{{selectedBrand ? 'Edit' : 'Create'}}"
              styleClass="p-button-text">
            </p-button>
            <!-- <p-confirmPopup></p-confirmPopup> -->
            <ng-template #spinner>
              <mat-progress-spinner
                class="brands-manager__spinner"
                diameter="30"
                mode="indeterminate"
                value="80">
              </mat-progress-spinner>
            </ng-template>
          </div>
        </form>
      </p-dialog>

      <brands-grid
        [brands]="brands"
        (onClickBrand)="editBrand($event)"
      ></brands-grid>
    </div>
  </div>
</div>
