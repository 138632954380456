import { Component, OnInit } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'social-media-bar',
  templateUrl: './social-media-bar.component.html',
  styleUrls: ['./social-media-bar.component.scss']
})
export class SocialMediaBarComponent implements OnInit {
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;

  constructor(private settingsService: SettingsService) { }

  ngOnInit(): void {
  }

  get instagramLink() {
    return `${this.settingsService.getInstagram.settingValue}`;
  }

  get facebookLink() {
    return `${this.settingsService.getFacebook.settingValue}`;
  }

  get whatsappLink() {
    return `https://wa.me/${this.settingsService.getWhatsappOne.settingValue}`;
  }

  launchUrl(url: string) {
    window.open(url, '_blank');
  }
}
