import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cart } from 'src/app/core/models/cart';
import { ProductOrder } from 'src/app/core/models/product-order';
import { ConfirmationService } from 'primeng/api';
import { ENGLISH, I18nService } from 'src/app/core/services/i18n.service';
import { Subscription } from 'rxjs';

export interface ChangeProductModel {
  count: number;
  productOrder: ProductOrder;
}

@Component({
  selector: 'cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss']
})
export class CartContentComponent implements OnInit {
  @Input('cart') cart: Cart;
  @Input('canDelete') canDelete: boolean = false;
  @Input('canChangeCount') canChangeCount: boolean = false;
  @Output('onClickProduct') onClickProduct: EventEmitter<void> = new EventEmitter<void>();
  @Output('onChangeCount') onChangeCount: EventEmitter<ChangeProductModel> = new EventEmitter<ChangeProductModel>();
  @Output('onRemoveProduct') onRemoveProduct: EventEmitter<string> = new EventEmitter<string>();
  subscription: Subscription;
  lang: string;

  constructor(
    private confirmationService: ConfirmationService,
    public i18nService: I18nService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    );
  }

  get isEnglish() {
    return this.lang === ENGLISH;
  }

  get products() {
    return this.cart.productOrders;
  }

  get count() {
    return this.cart.count;
  }

  get price() {
    return this.cart.price;
  }

  removeProduct(event: Event, uid: string) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dou you want to remove this product form the cart?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onRemoveProduct?.emit(uid);
      },
      reject: () => {}
    });
  }

  changeCount(count: number, productOrder: ProductOrder) {
    this.onChangeCount.emit({ productOrder, count });
  }

  clickProduct() {
    this.onClickProduct?.emit();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
