import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart } from 'src/app/core/models/cart';
import { Order } from 'src/app/core/models/order';
import { OrderService } from 'src/app/core/services/order.service';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  items$!: Observable<Order[]>;
  loading$!: Observable<boolean>;
  selectedCart: Cart = null;
  blockedDocument = false;
  showArchived = false;

  constructor(private ordersService: OrderService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.ordersService.clearAllNew();
    this.items$ = this.ordersService.getOrders(this.showArchived);
    this.loading$ = this.ordersService.loading;
  }

  openOrder(cart: Cart) {
    this.selectedCart = cart;
  }

  async removeOrder(order: Order) {
    this.blockedDocument = true;
    if (this.showArchived) {
      await this.ordersService.deleteOrder(order);
    } else {
      await this.ordersService.archiveOrder(order);
      let orderSetting = this.settingsService.getOrders;
      await this.settingsService.updateSetting({
        ...orderSetting!,
        settingValue: --(orderSetting!.settingValue as number)
      });
    }
    this.blockedDocument = false;
  }

  toggleArchived() {
    this.showArchived = !this.showArchived;
    this.items$ = this.ordersService.getOrders(this.showArchived);
    this.loading$ = this.ordersService.loading;
  }

  hideModal($event: any) {
    this.selectedCart = null;
  }
}
