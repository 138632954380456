import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService, USD } from 'src/app/core/services/settings.service';

@Pipe({
  name: 'sale'
})
export class SalePipe implements PipeTransform {

  constructor(private settingsService: SettingsService) { }

  transform(price: number, ...args: [number]): string {
    let activeUnit = this.settingsService.activeUnit;
    if (!activeUnit) {
      return '';
    }
    let unitName = activeUnit.symbol;
    if (activeUnit.name === USD) {
      return `${((price * (1 - (args[0] / 100))) * activeUnit.rate).toFixed(2)} ${unitName}`;
    }
    let lbpPrice = Math.round(((price * (1 - (args[0] / 100))) * activeUnit.rate) / 100) * 100;
    return `${lbpPrice.toFixed(0)} ${unitName}`;
  }
}
