import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ProductsComponent } from './products/products.component';
import { BrandsComponent } from './brands/brands.component';
import { SharedModule } from '../shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { ProductComponent } from './product/product.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { PagesComponent } from './pages.component';
import { AdminRoutingModule } from '../admin/admin-routing.module';
import { MaterialModule } from '../material/material.module';


@NgModule({
  declarations: [
    PagesComponent,
    NotFoundPageComponent,
    ProductsComponent,
    BrandsComponent,
    ProductComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    MatPaginatorModule,
    MatInputModule,
    FontAwesomeModule,
    MatIconModule,
    MaterialModule
  ]
})
export class PagesModule { }
