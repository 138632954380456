<div class="login">
  <div class="login__container">
    <div class="login__logo"></div>
    <form [formGroup]="loginForm" class="login__form">
      <h1 class="login__title">Log in.</h1>

      <p class="login__info">Only admins have access to the administration portal.</p>

      <mat-form-field appearance="fill" class="login__email-field">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"
               placeholder="Ex. pat@example.com">
        <mat-error *ngIf="loginForm.get('email')?.hasError('email') && !loginForm.get('email')?.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill"  class="login__password-field">
        <mat-label>Password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" [errorStateMatcher]="matcher">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.get('password')?.hasError('minlength') && !loginForm.get('password')?.hasError('required')">
          Password should at least 8 characters
        </mat-error>
        <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="showErrorMessage" class="login__failed-message">
        Invalid username or password. Please try again
      </mat-error>

      <button
        *ngIf="!isLoading; else spinner"
        class="login__button"
        mat-flat-button color="primary"
        (click)="login()"
      >
        Log in
      </button>

      <ng-template #spinner>
        <mat-progress-spinner
          class="login__spinner"
          diameter="30"
          mode="indeterminate"
          value="80">
        </mat-progress-spinner>
      </ng-template>

      <a *ngIf="!isLoading" class="login__forgot-password" (click)="forgotPassword()">Forgot Password?</a>
    </form>
    <div class="login__powered-by">
      <powered-by></powered-by>
    </div>
  </div>
</div>
