import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private subscription: Subscription | null = null;

  constructor(private storage: AngularFireStorage) {}

  uploadFile(filePath: string, file: any): Promise<Observable<string>> {
    return new Promise((resolve) => {
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      this.subscription = task.snapshotChanges().pipe(
        finalize(() => resolve(fileRef.getDownloadURL()) )
      ).subscribe();
    });
  }

  deleteFile(downloadURL: string) {
    return this.storage.storage.refFromURL(downloadURL).delete();
  }

  getFileSize(filePath: string) {
    let ref = this.storage.ref(filePath);
    return ref.getMetadata().toPromise();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
