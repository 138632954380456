import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cart } from 'src/app/core/models/cart';
import { Order } from 'src/app/core/models/order';
import { ProductOrder } from 'src/app/core/models/product-order';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  @Input('item') item: Order;
  @Output('onOpen') onOpen: EventEmitter<Cart> = new EventEmitter<Cart>();
  @Output('onRemove') onRemove: EventEmitter<Order> = new EventEmitter<Order>();

  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
  }

  get cart() {
    let products = JSON.parse(this.item.productOrders) as Array<ProductOrder>;
    let cart = new Cart();
    cart.productOrders = [...products];
    return cart;
  }

  open() {
    this.onOpen.emit(this.cart);
  }

  get createAt() {
    return new Date(this.item.createdAt);
  }

  remove(event: Event) {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dou you want to remove this order form the list?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onRemove.emit(this.item);
      },
      reject: () => {}
    });
  }
}
