<div class="new-product-card">
  <div class="new-product-card__img" [ngClass]="{'new-product-card__img_is-arabic': lang === 'ar'}">
    <img class="new-product-card__img" src={{product.imageUrl}} alt="">
  </div>
  <div class="new-product-card__content">
    <span class="new-product-card__title">
      {{lang === 'en' ? product.name : product.nameAr}}
    </span>
    <img class="new-product-card__brand" src={{getBrandImage()}} alt="">
    <div class="new-product-card__go-to-product" (click)="showProduct()">
      {{i18nService.data.homePage.showProduct}}
    </div>
  </div>
</div>
