import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Brand } from '../models/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  private _collectionName = 'brands';
  _brands: Brand[] = [];

  constructor(private afs: AngularFirestore) { }

  get brands() {
    return this._brands;
  }

  createBrand(brand: Brand) {
    return this.afs.collection(this._collectionName).add({ ...brand });
  }

  updateBrand(brand: Brand) {
    if (!brand.uid) {
      throw new Error('BrandsService#updateBrand: Brand should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(brand.uid)
      .update({
        name: brand.name,
        imageSize: brand.imageSize,
        imageRef: brand.imageRef,
        imageUrl: brand.imageUrl,
      })
  }

  deleteBrand(brand: Brand) {
    if (!brand.uid) {
      throw new Error('BrandsService#deleteBrand: Brand should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(brand.uid)
      .delete();
  }

  async loadBrands() {
    return new Promise((resolve, _) => {
      this.afs
        .collection(this._collectionName)
        .snapshotChanges()
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Brand;
            const uid = a.payload.doc.id;
            return { uid, ...data };
          }))
        )
        .subscribe(res => {
          this._brands = res;
          resolve(true);
        });
    });
  }
}
