import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { OrderListModule } from 'primeng/orderlist';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ChipModule } from 'primeng/chip';
import { KnobModule } from 'primeng/knob';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { BlockUIModule } from 'primeng/blockui';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FileUploadModule,
    ProgressBarModule,
    OrderListModule,
    BadgeModule,
    ButtonModule,
    DialogModule,
    ConfirmPopupModule,
    GalleriaModule,
    ChipModule,
    KnobModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    TableModule,
    InputNumberModule,
    BlockUIModule
  ],
  providers: [
    ConfirmationService,
    MessageService
  ],
  exports: [
    CommonModule,
    FileUploadModule,
    ProgressBarModule,
    OrderListModule,
    BadgeModule,
    ButtonModule,
    DialogModule,
    ConfirmPopupModule,
    GalleriaModule,
    ChipModule,
    KnobModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    TableModule,
    InputNumberModule,
    BlockUIModule
  ]
})
export class NgPrimeModule { }
