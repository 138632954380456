<div class="shopping-cart">
  <div
    id="cart"
    class="shopping-cart__icon"
    [matBadge]="productsCount"
    matBadgeColor="warn"
    matBadgeSize="small"
    [matBadgeHidden]="productsCount === 0"
    (click)="showCart()"
    [ngClass]="{'shopping-cart__icon_is-animate': animate}"
  >
  </div>
  <!-- <label for="cart" class="shopping-cart__label">
    {{i18nService.data.global.cart}}
  </label> -->
</div>
