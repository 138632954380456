<div class="best-seller-card" (click)="showProduct()">
  <div class="best-seller-card__img">
    <img class="best-seller-card__img" src={{product.imageUrl}} alt="">
    <div *ngIf="product.isSoldOut" class="best-seller-card__img_is-sold-out">
      <div class="best-seller-card__sold-out">{{i18nService.data.global.soldOut}}</div>
    </div>
  </div>
  <img class="best-seller-card__brand" src={{getBrandImage()}} alt="">
  <div class="best-seller-card__content">
    <span class="best-seller-card__title">
      {{lang === 'en' ? product.name : product.nameAr}}
    </span>
    <span class="best-seller-card__category">
      {{product.category}}
    </span>
    <span class="best-seller-card__price">
      {{product.price | price}}
    </span>
  </div>
</div>
