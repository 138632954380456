import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nService } from './services/i18n.service';
import { HandleErrorService } from './services/handle-error.service';
import { AuthService } from './services/auth.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    I18nService,
    HandleErrorService,
    AuthService
  ]
})
export class CoreModule { }
