import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'src/app/core/utils/capitalize-query-params';

@Pipe({
  name: 'capitalizeString'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return capitalize(value);
  }

}
