<div class="animated-product-card">
  <div class="animated-product-card__img">
    <div class="loading-mask_height_250 loading-mask_color_blue"></div>
  </div>
  <div class="animated-product-card__content">
    <div class="animated-product-card__title">
      <div class="loading-mask loading-mask_type_thin loading-mask_color_blue" style="width:160px;"></div>
    </div>
    <div class="animated-product-card__category">
      <div class="loading-mask loading-mask_type_thin" style="width:150px;"></div>
    </div>
    <div class="animated-product-card__price">
      <div class="loading-mask loading-mask_type_thin" style="width:150px;"></div>
    </div>
  </div>
</div>
