import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AppErrorStateMatcher } from 'src/app/shared/utils/error-state-matcher';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  matcher = new AppErrorStateMatcher();

  hide = true;

  isLoading = false;

  showErrorMessage = false;

  mode: ProgressSpinnerMode = 'determinate';

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]]
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.showErrorMessage = false;
    this.isLoading = true;
    this.authService
      .SignIn(`${this.loginForm.get('email')?.value}`.trim(), `${this.loginForm.get('password')?.value}`.trim())
      .then(_ => this.router.navigate(['/admin-portal']))
      .catch(err => {
        this.showErrorMessage = true;
        console.error('Something is wrong:', err.message);
      })
      .finally(
        () => this.isLoading = false
      );
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }
}
