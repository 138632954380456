<div class="bests-manager">
  <div #container class="bests-manager__container">
    <div class="bests-manager__header">
      <div class="bests-manager__title">
        Best Sellers
      </div>
    </div>

    <div class="bests-manager__content">
      <div #products *ngIf="(items$ | async); let products;" class="bests-manager__products-container">
        <div *ngIf="products.length > 0; else loading" class="bests-manager__list">
          <p-messages *ngIf="products.length > 4" [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
          <product-item
            *ngFor="let product of products"
            [product]=product
            [canAddAsSpecial]="false"
            [isEditable]="false"
            (onDelete)="remove(product)"
            deleteLabel="Remove"
            deleteDialogMessage="Are you sure that you want to remove this product from best sellers?"
            [isRunning]="isLoading"
          >
          </product-item>
          <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
          <div style="height: 20px;"></div>
        </div>
        <ng-template #loading>
          <div class="bests-manager__list">
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <product-item-loading *ngIf="loading$ | async;"></product-item-loading>
            <div *ngIf="!(loading$ | async);" class="bests-manager__empty-state-container">
              <div class="bests-manager__empty-stats-text">
                No Products found.
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
