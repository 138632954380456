<div class="dashboard-plans">
  <h3 class="dashboard-plans__title">Database and storage Plans</h3>
  <div class="dashboard-plans__free">Free Plan</div>
  <div class="dashboard-plans__paid">Paid Plan<br /><span class="dashboard-plans__paid-hint">(Free plan included*)</span></div>
  <div class="dashboard-plans__database">Database</div>
  <div class="dashboard-plans__stored-data-label">Stored data</div>
  <div class="dashboard-plans__stored-data-free">1 GiB total</div>
  <div class="dashboard-plans__stored-data-paid">$0.18/GiB</div>
  <div class="dashboard-plans__network-label">Network egress</div>
  <div class="dashboard-plans__network-free">10GiB/month</div>
  <div class="dashboard-plans__network-paid">
    <a href="https://cloud.google.com/firestore/pricing" target="_blank">
      Google Cloud pricing
    </a>
  </div>
  <div class="dashboard-plans__doc-writes-label">Document writes</div>
  <div class="dashboard-plans__doc-writes-free">20K/day</div>
  <div class="dashboard-plans__doc-writes-paid">$0.18/100K</div>
  <div class="dashboard-plans__doc-reads-label">Document reads</div>
  <div class="dashboard-plans__doc-reads-free">50K/day</div>
  <div class="dashboard-plans__doc-reads-paid">$0.06/100K</div>
  <div class="dashboard-plans__doc-deletes-label">Document deletes</div>
  <div class="dashboard-plans__doc-deletes-free">20K/day</div>
  <div class="dashboard-plans__doc-deletes-paid">$0.02/100K</div>
  <div class="dashboard-plans__storage">Storage</div>
  <div class="dashboard-plans__gb-stored-label">GB Stored</div>
  <div class="dashboard-plans__gb-stored-free">5 GB</div>
  <div class="dashboard-plans__gb-stored-paid">$0.026/GB</div>
  <div class="dashboard-plans__gb-download-free">1 GB/day</div>
  <div class="dashboard-plans__gb-download-label">GB download</div>
  <div class="dashboard-plans__gb-download-paid">$0.12/GB</div>
  <div class="dashboard-plans__upload-op-label">Upload operations</div>
  <div class="dashboard-plans__upload-op-free">20K/day</div>
  <div class="dashboard-plans__upload-op-paid">$0.05/10K</div>
  <div class="dashboard-plans__download-op-label">Download operation</div>
  <div class="dashboard-plans__download-op-free">50K/day</div>
  <div class="dashboard-plans__download-op-paid">$0.004/10K</div>
</div>
