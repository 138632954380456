import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { OrderService } from 'src/app/core/services/order.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {
  tooltipPosition: TooltipPosition = 'right';
  ordersCount = 0;
  subscription: Subscription;
  notificationSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ordersService: OrderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.subscription = this.ordersService.getNewOrders().subscribe(
      res =>  this.ordersCount = res.length
    );
  }

  ngAfterViewInit() {
    this.notificationSubscription = this.ordersService.notifications.subscribe(
      res => {
        if (res.length) {
          setTimeout(() => {
            this.playAudio();
            this.messageService.add({
              key: 'notification',
              severity:'custom',
              summary: 'You received a new Order',
              detail: 'We have a new order',
              icon: 'pi-bell',
              life: 5000
            });
            for (let notification of res) {
              this.ordersService.deleteNotification(notification.uid);
            }
          }, 2000);
        }
      }
    );
  }

  get hasOrders() {
    return this.ordersCount > 0;
  }

  playAudio(){
    try {
      let audio = new Audio();
      audio.src = "../../../../assets/audio/notification.wav";
      audio.autoplay = true;
      audio.load();
      audio.play();
    } catch(error) {
      console.error('error', error);
    }
  }

  clickNotification() {
    this.messageService.clear();
    this.navigateTo('orders');
  }

  navigateTo(url: string) {
    this.router.navigate([`/admin-portal/${url}`]);
  }

  goToSite() {
    this.router.navigate(['/']);
  }

  get url() {
    return this.router.url.split('/')[2];
  }

  logout() {
    this.authService
      .SignOut()
      .then(_ => this.router.navigate(['/login']));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.notificationSubscription?.unsubscribe();
  }
}
