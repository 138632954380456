import { Component, OnInit } from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  constructor(public i18nService: I18nService, private router: Router) { }

  ngOnInit(): void {
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }
}
