import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../models/order';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { map, catchError, tap } from 'rxjs/operators';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

export const STATES = [
  'Akkar', 'Aley', 'Baabda', 'Baalbak', 'Batround', 'Bcharre', 'Beirut', 'Bint Jbeil',
  'Chouf', 'Hasbayya', 'Hermel', 'Jbeil', 'Jezzin', 'Kesrouan', 'Koura', 'Marjaayoun', 'Metn',
  'Minieh-Dinnieh', 'Nabatieh', 'Rachaya', 'Saida', 'Sour', 'Tripoli', 'West Bekaa', 'Zahle', 'Zgharta'
] as const;

interface NewOrderModel {
  order: string;
}

interface NotificationModel {
  uid?: string;
  date: Date;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _collectionName = 'orders';
  private _newOrderCollectionName = 'newOrders';
  private _notificationsCollectionName = 'notifications';
  private _loading = new BehaviorSubject<boolean>(false);
  loading: Observable<boolean> = this._loading;

  constructor(private afs: AngularFirestore,) { }

  createOrder(order: Order) {
    return this.afs.collection(this._collectionName).add({ ...order });
  }

  createNewOrder(uid: string) {
    return this.afs.collection(this._newOrderCollectionName).add({
      order: uid
    });
  }

  async createNotification() {
    let notifications = await this.getNotificationsPromise();
    if (notifications.length === 0) {
      return this.afs.collection(this._notificationsCollectionName).add({
        date: new Date()
      });
    }
  }

  get getAllNotifications(): Observable<NotificationModel[]> {
    return this.afs
      .collection(this._notificationsCollectionName)
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as NotificationModel;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        }))
      );
  }

  async getNotificationsPromise(): Promise<NotificationModel[]> {
    return new Promise((resolve, _) => {
      let subscription = this.getAllNotifications
        .subscribe(res => {
          subscription.unsubscribe();
          resolve(res);
        });
    });
  }

  get notifications(): Observable<NotificationModel[]> {
    return this.afs
      .collection(this._notificationsCollectionName)
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as NotificationModel;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        }))
      );
  }

  deleteNotification(uid: string) {
    if (!uid) {
      throw new Error('OrderService#deleteNotification: Notification should contain a uid');
    }
    return this.afs.collection(this._notificationsCollectionName)
      .doc(uid)
      .delete();
  }

  archiveOrder(order: Order) {
    if (!order.uid) {
      throw new Error('OrderService#updateOrder: Order should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(order.uid)
      .update({
        productOrders: order.productOrders,
        createdAt: order.createdAt,
        fullName: order.fullName,
        address: order.address,
        state: order.state,
        phone: order.phone,
        country: order.country,
        isArchived: true
      })
  }

  deleteOrder(order: Order) {
    if (!order.uid) {
      throw new Error('OrderService#deleteOrder: Order should contain a uid');
    }
    return this.afs.collection(this._collectionName)
      .doc(order.uid)
      .delete();
  }

  getOrders(isArchived: boolean = false): Observable<Order[]> {
    this._loading.next(true);
    return this.afs
      .collection(this._collectionName, ref => {
        let query : CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        if (isArchived) { query = query.where('isArchived', '==', true) };
        if (!isArchived) { query = query.where('isArchived', '==', false) };
        return query;
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Order;
          Object.keys(data).filter(key => key === 'createdAt')
            .forEach(key => data[key] = data[key].toDate())
          const uid = a.payload.doc.id;
          return { uid, ...data };
        })),
        tap(_ => this._loading.next(false)),
        catchError(error => { throw error; })
      );
  }

  async clearAllNew() {
    let db = this.afs.firestore;
    return db.collection(this._newOrderCollectionName).get()
      .then(querySnapshot => {
        var batch = db.batch();

        querySnapshot.forEach(doc => {
            doc.data() as NewOrderModel;
            batch.delete(doc.ref);
        });

        // Commit the batch
        return batch.commit();
      });
  }

  getNewOrders() {
    return this.afs
      .collection(this._newOrderCollectionName)
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as NewOrderModel;
          return { ...data };
        })),
        catchError(error => { throw error; })
      );
  }
}
