import { Component, Input, OnInit } from '@angular/core';
import { Chip } from 'src/app/core/models/chip';

@Component({
  selector: 'app-chips-bar',
  templateUrl: './chips-bar.component.html',
  styleUrls: ['./chips-bar.component.scss']
})
export class ChipsBarComponent implements OnInit {
  @Input('chips') chips: Chip[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
