<div>
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
  <div *ngIf="innerWidth > 500 || showFAB" class="fab">
    <button
      mat-fab
      aria-label="whatsapp"
      class="whatsapp"
      (click)="launchUrl(whatsappOneLink)"
    >
      <fa-icon
        class="social-media-bar_icon"
        [icon]="faWhatsapp"
        size="2x"
      >
      </fa-icon>
    </button>
  </div>
</div>
<!-- <div [dir]="langDirection">
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
</div> -->
