<div class="cart-table">
  <cart-content
    *ngIf="!isLoading; else loading"
    style="width: 100%;"
    [cart]="cart"
    [canDelete]="true"
    [canChangeCount]="true"
    (onClickProduct)="clickProduct()"
    (onChangeCount)="changeCount($event)"
    (onRemoveProduct)="removeProduct($event)"
  >
  </cart-content>
  <ng-template #loading>
    <mat-progress-spinner
      class="cart-table__spinner"
      diameter="70"
      mode="indeterminate"
      value="80">
    </mat-progress-spinner>
  </ng-template>
</div>
