<div class="product-card">
  <div class="product-card__body" (click)="showProduct()">
    <div class="product-card__img-container">
      <img class="product-card__img" src={{product.imageUrl}} alt="">
    </div>
    <!-- <img
      [hidden]="!isLoadBrandImageLoaded"
      class="product-card__brand"
      src={{brandImage}} alt=""
      (load)="isLoadBrandImageLoaded = true"
    >
    <img *ngIf="!isLoadBrandImageLoaded" class="product-card__brand" src="assets/images/loadbrand.png" alt=""> -->
    <span class="product-card__brand-name">
      {{brandName}}
    </span>
    <div class="product-card__content">
      <span class="product-card__title">
        {{lang === 'en' ? product.name : product.nameAr}}
      </span>
      <span *ngIf="product.sale === 0" class="product-card__price product-card__price_margin-bottom_10">
        {{product.price | price}}
      </span>
      <div *ngIf="product.sale !== 0" class="product-card__price-with-sale">
        <span *ngIf="!product.isSoldOut" class="product-card__sale">
          Sale - {{product.sale}}%
        </span>
        <span class="product-card__old-price">
          {{product.price | price}}
        </span>
        <span class="product-card__price">
          {{product.price | sale: product.sale}}
        </span>
      </div>
    </div>
    <div *ngIf="product.isSoldOut" class="product-card__sold-out">
      {{i18nService.data.global.soldOut}}
    </div>
  </div>
  <div class="product-card__add-to-cart">
    <span class="product-card__category">
      {{product.subcategory ? getSubcategory(product.subcategory) : getCategory(product.category)}}
    </span>
    <div *ngIf="!product.isSoldOut" class="product-card__add-to-card-icon" (click)="addToCart()"></div>
  </div>
</div>
