import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  @Input('logo') logo: string = '';
  @Input('title') title: string = '';
  @Input('subtitle') subtitle: string = '';


  constructor() { }

  ngOnInit(): void {
  }

}
