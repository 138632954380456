<div class="dashboard">
  <div class="dashboard__container">
    <div class="dashboard__header">
      <div class="dashboard__title">
        Dashboard
      </div>
      <powered-by [is-clickable]="true"></powered-by>
    </div>
    <div class="dashboard__content">
      <dashboard-storage-capacity
        class="dashboard__storage-capacity"
      ></dashboard-storage-capacity>
      <div class="dashboard__infos">
        <dashboard-plans></dashboard-plans>
      </div>
      <dashboard-card
        logo="widgets"
        title="Products"
        subtitle="Manage your products"
        class="dashboard__card dashboard__card_is-products"
        (click)="navigateTo('items')"
      >
        <div class="dashboard__products">
          <span class="dashboard__products-count">{{products}}</span>
          <span class="dashboard__products-title">Products</span>
        </div>
      </dashboard-card>
      <dashboard-card
        logo="category"
        title="Brands"
        subtitle="Manage your brands"
        class="dashboard__card dashboard__card_is-brands"
        (click)="navigateTo('markers')"
      >
        <div class="dashboard__brands">
          <div class="dashboard__brands-info">
            <span class="dashboard__brands-count">{{brands}}</span>
            <span class="dashboard__brands-title">Brands</span>
          </div>
        </div>
      </dashboard-card>
      <dashboard-card
        logo="local_shipping"
        title="Orders"
        subtitle="Check the new orders"
        class="dashboard__card dashboard__card_is-orders"
        (click)="navigateTo('orders')"
      >
        <div class="dashboard__orders">
          <span class="dashboard__orders-count">{{orders}}</span>
          <span class="dashboard__orders-title">Orders</span>
        </div>
      </dashboard-card>
    </div>
  </div>
</div>
