export function capitalize(str: string) {
  if (!str) {
    return null;
  }
  var splitStr = str.toLowerCase().split('-');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function dasherize(str: string) {
  return str ? str.toLowerCase().split(' ').join('-') : null;
}
