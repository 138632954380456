import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/core/models/product';
import { Subscription } from 'rxjs';
import { I18nService } from 'src/app/core/services/i18n.service';
import { BrandsService } from 'src/app/core/services/brands.service';
import { Router } from '@angular/router';

@Component({
  selector: 'new-product-card',
  templateUrl: './new-product-card.component.html',
  styleUrls: ['./new-product-card.component.scss']
})
export class NewProductCardComponent implements OnInit {
  @Input('product') product: Product;
  subscription: Subscription;
  lang: string;

  constructor(public i18nService: I18nService,
    private router: Router,
    private brandService: BrandsService) { }

  ngOnInit() {
    this.subscription = this.i18nService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    );
  }

  getBrandImage() {
    return this.brandService.brands.find(brand => brand.name === this.product.brand)?.imageUrl;
  }

  showProduct() {
    this.router.navigate(['/main/product'], { queryParams: { product: this.product.uid } });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
