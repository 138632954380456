import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Brand } from 'src/app/core/models/brand';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'brands-grid',
  templateUrl: './brands-grid.component.html',
  styleUrls: ['./brands-grid.component.scss']
})
export class BrandsGridComponent implements OnInit {
  @Input('brands') brands: Brand[] = [];
  @Output('onClickBrand') onClickBrand: EventEmitter<Brand> = new EventEmitter<Brand>();

  constructor(public i18nService: I18nService) { }

  ngOnInit(): void {
  }

  goToProduct($event: Brand) {
    this.onClickBrand.emit($event);
  }
}
