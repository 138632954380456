import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input('width') width: string = '100%';
  @Input('height') height: string = '400px';
  controllerSrc: any;

  constructor(private sanitizer: DomSanitizer, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.controllerSrc = this.getSafeUrl(this.mapUrl);
  }

  get mapUrl() {
    return `${this.settingsService.getMap.settingValue}`;
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
