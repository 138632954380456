import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  storage = 0;
  products = 0;
  brands = 0;
  orders = 0;

  constructor(private router: Router,
    public settingsService: SettingsService) { }

  ngOnInit(): void {
    this.setupSettings();
  }

  setupSettings() {
    this.storage = (this.settingsService.getStorage?.settingValue as number) ?? 0;
    this.products = (this.settingsService.getProducts?.settingValue as number) ?? 0;
    this.brands = (this.settingsService.getBrands?.settingValue as number) ?? 0;
    this.orders = (this.settingsService.getOrders?.settingValue as number) ?? 0;
  }

  navigateTo(url: string) {
    this.router.navigate([`/admin-portal/${url}`]);
  }
}
